/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./five-paisa.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./five-paisa.component";
import * as i3 from "../shared/services/common/common.service";
import * as i4 from "@angular/router";
var styles_FivePaisaComponent = [i0.styles];
var RenderType_FivePaisaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FivePaisaComponent, data: {} });
export { RenderType_FivePaisaComponent as RenderType_FivePaisaComponent };
export function View_FivePaisaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "uk-container uk-container-large uk-height-large"], ["uk-height-viewport", "expand: true"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "uk-text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["uk-spinner", "ratio: 3"]], null, null, null, null, null))], null, null); }
export function View_FivePaisaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-five-paisa", [], null, null, null, View_FivePaisaComponent_0, RenderType_FivePaisaComponent)), i1.ɵdid(1, 114688, null, 0, i2.FivePaisaComponent, [i3.CommonService, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FivePaisaComponentNgFactory = i1.ɵccf("app-five-paisa", i2.FivePaisaComponent, View_FivePaisaComponent_Host_0, {}, {}, []);
export { FivePaisaComponentNgFactory as FivePaisaComponentNgFactory };
