import { Component, OnInit } from '@angular/core';
import { CommonService } from "../shared/shared.module";
import { Router } from '@angular/router';


@Component({
  selector: 'app-advisory-fees',
  templateUrl: './advisory-fees.component.html',
  styleUrls: ['./advisory-fees.component.scss']
})
export class AdvisoryFeesComponent implements OnInit {

  activeFrequency: any = {};
  isUserLoggedIn: string = "";
  isCoupounErr: string = "";
  coupounC: string;
  constructor(private commonService: CommonService, private router: Router) { }

  ngOnInit() {
    this.getAllFrequency();
    this.isLoggedIn();
  }


  getAllFrequency() {
    this.commonService.getAllPaymentfrequency().subscribe((res: any) => {
      if (res && res.statusCode) {
        this.activeFrequency = res.data ? res.data.find((d) => d.isActive) : "";
        this.activeFrequency ? this.activeFrequency.noOfDays = Math.floor(this.activeFrequency.noOfDays / 30) : this.activeFrequency;
      }
    })
  }

  isLoggedIn() {
    this.isUserLoggedIn = this.commonService.getToken();
  }

  clickPromocode() {
    !this.isUserLoggedIn && this.commonService.showNotification("Please Login to apply for promocode", "danger");
    return true;
  }


  checkCoupounCode(coupounC: string) {
    if (coupounC.length == 6) {
      this.commonService.checkCoupoun(coupounC).subscribe((res: any) => {
        if (res && res.statusCode) {
          this.coupounC = coupounC;
        } else {
          this.isCoupounErr = res && res.msg ? res.msg : "Something went wrong!!";
        }
      });
    } else {
      this.isCoupounErr = null;
    }

  }

  navigateToPayment() {
    let userId = this.commonService.getUserId();
    this.coupounC = this.coupounC ? this.coupounC : "-1";
    this.router.navigate([`/get-account-info/${userId}/${this.coupounC}/-1`]);
  }

}
