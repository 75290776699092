import { Component, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonService } from "../shared/services/common/common.service";
import { Router } from "@angular/router"


declare let UIkit: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: any
  errMsg: string = "";
  apiCall: boolean = false;
  constructor(private fb: FormBuilder, private commonService: CommonService, private router: Router) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      pin: ["", [Validators.required, Validators.maxLength(4), Validators.pattern("^[0-9]*$")]]
    });
  }

  get f() { return this.loginForm.controls; }

  verifyUser() {
    this.errMsg = "";
    if (this.loginForm.valid) {
      this.apiCall = !this.apiCall;
      this.commonService.loginUser(this.loginForm.value).subscribe((res: any) => {
        if (res && res.statusCode) {
          this.commonService.setToken(res.token);
          UIkit.modal('#login-form').hide();
          this.router.navigate(['/dashboard']);
          this.apiCall = !this.apiCall;
        } else {
          this.errMsg = res.msg;
        }
      })
    }
  }



}
