import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService, CommonService } from "../shared/shared.module";
@Component({
  selector: 'app-verified',
  templateUrl: './verified.component.html',
  styleUrls: ['./verified.component.scss']
})
export class VerifiedComponent implements OnInit {
  successMsg: string = 'Verified';
  queryParams: any = {};
  constructor(private route: ActivatedRoute, private commonService: CommonService, private router: Router, private dashboardService: DashboardService) { }


  ngOnInit() {
    this.getQuery();
  }

  getQuery() {
    this.route.queryParams.subscribe((params) => {
      this.successMsg = params.msg ? params.msg : this.successMsg;
      this.queryParams = params;
      if (this.queryParams.isWeb) {
        this.queryParams.isPayment ? this.navigateToOnBoard() : this.navigateToDashBoard();
      }
    });
  }

  navigateToOnBoard() {
    let title = this.queryParams.title ? this.queryParams.title : "";
    let amount = this.queryParams.amount ? this.queryParams.amount : "";
    if (title && amount) {
      this.commonService.showNotification(`${title} Rs. ${amount}`, "success");
      this.router.navigate(['/dashboard/pending-phase']);
    }
  }

  navigateToDashBoard() {
    this.commonService.getAccessTokenFromEmail(this.queryParams.accessToken).subscribe((res) => {
      if (res && res.statusCode) {
        this.commonService.setToken(res.accessToken);
        this.dashboardService.sendDashboardSubject("getUserInfo", "");
        setTimeout(() => this.router.navigate(['/dashboard/on-borading-stages']), 1000);
      }
    });
  }

}
