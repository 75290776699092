export const environment = {
  production: true,
  // url: "https://appapi.tavaga.com/api/v1/",
  // aliceBlueLogin: "https://ant.aliceblueonline.com/oauth2/auth?response_type=code&client_id=TAVAGA&redirect_uri=https://stageadmin.app.tavaga.com/alice-blue-response/&scope=orders&state=state_test&access_type=authorization_code",
  // five5paisaLogin: "https://dev-openapi.5paisa.com/WebVendorLogin/VLogin/Index?VendorKey=8MgIYyy3UbzNo5PW7gon4BfsdXXxA0gX&ResponseURL=https://stageadmin.app.tavaga.com/5paisa-response",
  url: "https://api.tavaga.com/api/v1/",
  aliceBlueLogin: "https://ant.aliceblueonline.com/?appcode=GjytQYXZNaVgBjJ",
  five5paisaLogin: "https://dev-openapi.5paisa.com/WebVendorLogin/VLogin/Index?VendorKey=8MgIYyy3UbzNo5PW7gon4BfsdXXxA0gX&ResponseURL=https://app.tavaga.com/5paisa-response",
  expiryInMin: 2000,
  razorPayJS: "https://checkout.razorpay.com/v1/checkout.js",
  iciciAPI: "https://api.icicidirect.com/",
  gClientId: "909369473094-t25gchbgtolfqhjubger5h5mcghdidj9.apps.googleusercontent.com",
  emailLimit: 40,
  bondPaymentLimit: 4,
  ipAddress: "https://api.ipify.org/?format=json",
  zerodhaAPIKey: "krlw0z84dmh2y4z1",
  zerodhaUrl: "https://kite.zerodha.com/",
  harmoneyAssests: "https://harmoney-static-data.s3.ap-south-1.amazonaws.com/",
  edelweissURL: "https://www.nuvamawealth.com/",
};
