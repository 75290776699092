import { Pipe, PipeTransform } from "@angular/core";
import { CommonService } from "../services/common/common.service";

@Pipe({
    name: "moneyAbv",
})

export class MoneyAbv implements PipeTransform {

    constructor() { }

    transform(amount: any): any {
        let symbol: any;
        if (amount < 1e3) symbol = amount;
        else if (amount >= 1e3 && amount < 1e5) symbol = (amount / 1e3).toFixed(2) + "K";
        else if (amount >= 1e5 && amount <= 1e7) symbol = (amount / 1e5).toFixed(2) + "L";
        else symbol = ((amount / 1e8) * 10 ).toFixed(1) + "Cr";
        // else symbol = (amount / 1e5).toFixed(1) + "L";
        return symbol;
    }
}
