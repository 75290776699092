/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./five-paisa-response.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./five-paisa-response.component";
import * as i3 from "../shared/services/common/common.service";
import * as i4 from "@angular/router";
import * as i5 from "../shared/services/brokers/brokers.service";
var styles_FivePaisaResponseComponent = [i0.styles];
var RenderType_FivePaisaResponseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FivePaisaResponseComponent, data: {} });
export { RenderType_FivePaisaResponseComponent as RenderType_FivePaisaResponseComponent };
export function View_FivePaisaResponseComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "uk-section uk-section-default uk-section-xlarge"], ["uk-height-viewport", "expand: true"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "uk-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "uk-flex uk-flex-column uk-flex-middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "uk-margin-small-right"], ["uk-spinner", "ratio: 2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "uk-text-emphasis uk-text-center uk-display-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Twiddle your thumbs"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "uk-text-emphasis uk-text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["while we link your broker"]))], null, null); }
export function View_FivePaisaResponseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-five-paisa-response", [], null, null, null, View_FivePaisaResponseComponent_0, RenderType_FivePaisaResponseComponent)), i1.ɵdid(1, 114688, null, 0, i2.FivePaisaResponseComponent, [i3.CommonService, i4.ActivatedRoute, i4.Router, i5.BrokersService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FivePaisaResponseComponentNgFactory = i1.ɵccf("app-five-paisa-response", i2.FivePaisaResponseComponent, View_FivePaisaResponseComponent_Host_0, {}, {}, []);
export { FivePaisaResponseComponentNgFactory as FivePaisaResponseComponentNgFactory };
