import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../shared/shared.module';


@Component({
  selector: 'app-redirection',
  templateUrl: './redirection.component.html',
  styleUrls: ['./redirection.component.scss']
})
export class RedirectionComponent implements OnInit {

  //accessToken, userId, redirectTo
  moduleRedirection: any = "";
  userInfo: any;
  constructor(private activatedRoute: ActivatedRoute, private commonService: CommonService, private router: Router) { }

  ngOnInit() {
    this.extractQuery();
  }


  extractQuery() {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      this.moduleRedirection = res;
      this.setToken();
    });
  }

  setToken() {
    let tokenObj = {
      id: this.moduleRedirection.aes,
      userId: this.moduleRedirection.id,
    }
    this.commonService.setToken(tokenObj);
    this.getUserDetails();
  }


  getUserDetails() {
    this.commonService.getUserInfo().subscribe((res: any) => {
      if (res) {
        this.userInfo = res;
        this.commonService.setUserInfo(res);
        this.redirectToModule();
      } else {
        this.commonService.showNotification("Invalid Request", "danger");
      }
    });
  }

  redirectToModule() {
    let redirectTo = this.moduleRedirection.redirectTo;
    redirectTo = redirectTo ? redirectTo.toUpperCase() : "";
    switch (redirectTo) {
      case 'BONDS':
        this.router.navigate(['bonds']);
        break;
      case 'BONDSPORTFOLIO':
        this.router.navigate(['bonds/portfolio']);
        break;

      default:
        this.router.navigate(['/']);
        break;
    }
  }

}
