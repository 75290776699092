import { Pipe, PipeTransform } from "@angular/core";


@Pipe({
    name: "fixedDecimal",
})

export class FixedDecimal implements PipeTransform {

    constructor() { }

    transform(value: any, fixedTo: number = 2): any {
        let val = "0.00";
        val = Number(value).toFixed(fixedTo);
        return val;
    }
}
