import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonService, moment, IciciService } from "../shared/shared.module";
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-icici-login',
  templateUrl: './icici-login.component.html',
  styleUrls: ['./icici-login.component.scss']
})
export class IciciLoginComponent implements OnInit {
  @ViewChild('iciciLogin', { static: false }) iciciLogin: ElementRef;

  userId: string = "";
  accessToken: string = "";
  editGoalId: string = "";
  iciciChecksumDetails: any = {};
  loginUrl = `${environment.iciciAPI}apiuser/tradelogin`;
  queryParams: any;

  constructor(private iciciService: IciciService, private commonService: CommonService, private router: ActivatedRoute) { }


  ngOnInit() {
    this.getQuery();
    this.setToken();
    this.setEditGoalId();
    this.getICICIChecksum();
  }

  getQuery() {
    this.router.params.subscribe((params) => {
      this.userId = params.userId ? params.userId : this.userId;
      this.accessToken = params.accessToken ? params.accessToken : this.accessToken;
      this.editGoalId = params.editGoalId ? params.editGoalId : this.editGoalId;
    });

    this.router.queryParams.subscribe((params) => {
      this.queryParams = params;
      this.queryParams && this.queryParams.isWeb && this.commonService.setLocalStorage('isWeb', true);
    })
  }

  setToken() {
    let tokenObj = {
      id: this.accessToken,
      userId: this.userId,
    }
    this.commonService.setToken(tokenObj);
  }

  getICICIChecksum() {
    this.iciciService.getICICIChecksum().subscribe((res) => {
      if (res && res.statusCode) {
        this.iciciChecksumDetails = res.icici;
        setTimeout(() => this.navigateIcici(), 1000);
      }
    });
  }

  setEditGoalId() {
    localStorage.removeItem("editGoalId");
    if (this.editGoalId != "-1") {
      let info: any = { editGoalId: this.editGoalId, dateAt: moment().format("MM/DD/YYYY") };
      info = JSON.stringify(info);
      localStorage.removeItem("editGoalId");
      localStorage.setItem("editGoalId", info);
    }
  }

  navigateIcici() {
    this.iciciLogin.nativeElement.submit();
  }
}
