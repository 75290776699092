import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { Router, ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';


const subject = new Subject();

declare let UIkit: any;

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  commonSubject = subject;

  constructor(private http: HttpClient, private router: Router, private activatedRoute: ActivatedRoute) {
    this.currentFinancialYear();
  }


  getCommonSubject(): Observable<any> {
    return this.commonSubject.asObservable();
  }

  sendCommonSubject(action: any, data: any) {
    this.commonSubject.next({ action, data })
  }


  sendUserEmail(email: string, deviceType: string, publicIp: string, referralUrl: string): Observable<any> {
    return this.http.get(`users/sendUserEmail/${email}/${deviceType}/${publicIp}?${referralUrl}`);
  }

  isEmailVerified(email: string) {
    return this.http.get(`users/IsEmailVerify/${email}`);
  }

  getClientIp() {
    return this.http.get(environment.ipAddress);
  }

  getToken() {
    return localStorage.getItem('aesToken');
  }


  getDataFromLocalStorage(keyName: string) {
    let keyData = localStorage.getItem(keyName);
    return keyData;
  }

  getDataFromSessionStorage(keyName: string) {
    let keyData = sessionStorage.getItem(keyName);
    return keyData;
  }

  setLocalStorage(keyName: string, data) {
    localStorage.setItem(keyName, data);
  }

  setSessionStorage(keyName: string, data) {
    sessionStorage.setItem(keyName, data);
  }

  setToken(token) {
    localStorage.setItem('aesToken', token.id);
    this.setUserInfo({ id: token.userId });
    this.setTTL();
  }

  setUserInfo(userInfo) {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  }

  setTTL() {
    localStorage.setItem('aesTTL', new Date().valueOf().toString());
  }

  removeToken() {
    localStorage.clear();
  }

  getUserId() {
    let userInfo: any = localStorage.getItem('userInfo');
    userInfo = userInfo && JSON.parse(userInfo);
    let userId = userInfo ? userInfo.id : "";
    return userId;
  }

  isAuthenticated() {
    let status = false;
    if (this.getToken()) {
      let aesTTL = localStorage.getItem('aesTTL');
      let duration = moment.duration(moment().diff(moment(Number(aesTTL))));
      status = duration.asMinutes() > environment.expiryInMin ? false : true;
    }
    return status;
  }

  loginUser(postBody) {
    return this.http.post(`users/webAppLogin`, postBody);
  }

  // primary	UIkit.notification("...", { status:'primary'})
  // success	UIkit.notification("...", { status:'success'})
  // warning	UIkit.notification("...", { status:'warning'})
  // danger	UIkit.notification("...", { status:'danger'})
  showNotification(message: string, status: string, pos = 'bottom-left') {
    UIkit.notification(message, { status, pos })
  }

  getETFPrices() {
    return this.http.get(`currentEtfs/getEtfDetails`);
  }

  currentFinancialYear() {
    let financialYears = [];
    let currentDate = moment();
    let years = -1;

    let currentFY = moment().month(3).startOf('month');
    if (currentDate <= currentFY) {
      for (let i = 0; i < 3; i++) {
        let cT = {
          start: moment().month(3).startOf('month').add(years, 'year'),
          end: moment().month(2).endOf('month').add('years', 1).add(years, 'year')
        };
        financialYears.push(cT);
        years--;
      }
    } else {
      years = 0;
      for (let i = 0; i < 3; i++) {
        let cT = {
          start: moment().month(3).startOf('month').add(years, 'year'),
          end: moment().month(2).endOf('month').add('years', 1).add(years, 'year')
        };
        financialYears.push(cT);
        years--;
      }
    }
    localStorage.setItem('financialYear', JSON.stringify(financialYears));
  }

  getETFs() {
    let etfs = localStorage.getItem('etfs');
    etfs = etfs ? JSON.parse(etfs) : [];
    return etfs;
  }

  setETFs(etfs: any) {
    localStorage.setItem('etfs', JSON.stringify(etfs));
  }


  pennyDrop(reqBody: any): Observable<any> {
    return this.http.post(`pennyDropAccounts/pennyDropService`, reqBody);
  }

  isPennyDrop(orderId: string): Observable<any> {
    return this.http.get(`pennyDropAccounts/isPennyDrop/${orderId}`);
  }

  getAccount(userId: string): Observable<any> {
    return this.http.get(`pennyDropAccounts/getAccount/`);
  }

  getTVPBank(): Observable<any> {
    return this.http.get(`tpvBanks/getTVPBank`);
  }

  getOrderId(userId, cc, paymentFrequency): Observable<any> {
    return this.http.get(`processingPayments/getOrderId/${userId}/${cc}/${paymentFrequency}`);
  }

  checkRazorPayStatus(orderId, paymentId, checksum): Observable<any> {
    return this.http.get(`processingPayments/checkRazorPayStatus/${orderId}/${paymentId}/${checksum}`);
  }

  getDateDifference(startDate, endDate, returnAs, dateFormate: string = null): number {
    let start = dateFormate ? moment(startDate, dateFormate) : moment(startDate);
    let end = endDate ? moment(endDate) : moment();
    let diff = 0;
    diff = end.diff(start, returnAs);
    return diff;
  }

  getUserInfo(): Observable<any> {
    return this.http.get(`users/currentUser`);
  }

  authenticateGoogleToken(reqBody, referralUrl): Observable<any> {
    return this.http.post(`/users/googleLogin?${referralUrl}`, reqBody);
  }

  getAccessTokenFromEmail(emailToken: string): Observable<any> {
    return this.http.get(`/users/getAccessToken/${emailToken}`);
  }

  convertMoneyToFraction(amount: any) {
    amount = isNaN(amount) ? amount : `${amount}`;
    return parseFloat(amount.replace(/,/g, ''));
  }

  subscribeUser(reqBody: any) {
    return this.http.post(`/subscribers`, reqBody);
  }

  sendRiskMail(reqBody: any) {
    return this.http.post(`/subscribers/mailRiskAnalysis`, reqBody);
  }

  getAllPaymentfrequency() {
    return this.http.get(`/admin/getAllFrequency`);
  }

  checkCoupoun(coupounCode: string) {
    return this.http.get(`/processingPayments/checkCoupoun/${coupounCode}`);

  }

  isWebRequest() {
    let isWeb = this.getDataFromLocalStorage('isWeb');
    return isWeb;
  }

  convertObjectToQuery(referralUrl) {
    return Object.entries(referralUrl).map(e => e.join('=')).join('&')
  }

  brokerImages(brokerName) {
    let brokerImage = '';
    switch (brokerName) {
      case 'ALICEBLUE':
        brokerImage = 'https://tavaga-assets.s3.ap-south-1.amazonaws.com/brokers/aliceblue.png';
        break;

      case 'FIVEPAISA':
        brokerImage = 'https://tavaga-assets.s3.ap-south-1.amazonaws.com/brokers/5paisa.png';
        break

      case 'KOTAK':
        brokerImage = 'https://tavaga-assets.s3.ap-south-1.amazonaws.com/brokers/kotak.png';
        break

      case 'DHAN':
        brokerImage = 'https://tavaga-assets.s3.ap-south-1.amazonaws.com/brokers/dhan_tparent_long.png';
        break;

      default:
        break;
    }

    return brokerImage;
  }

}
