import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { EmailVerifyComponent } from './email-verify/email-verify.component';
import { VerifiedComponent } from './verified/verified.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { RefuteComponent } from './refute/refute.component';
import { GetAccountInfoComponent } from './get-account-info/get-account-info.component';
import { IciciLoginComponent } from './icici-login/icici-login.component';
import { IciciResponseComponent } from './icici-response/icici-response.component';
import { IciciTradesComponent } from './icici-trades/icici-trades.component';
import { AdvisoryFeesComponent } from './advisory-fees/advisory-fees.component';
import { BrokerLoginComponent } from './broker-login/broker-login.component';
import { AliceBlueComponent } from './alice-blue/alice-blue.component';
import { FivePaisaComponent } from './five-paisa/five-paisa.component';
import { FivePaisaResponseComponent } from './five-paisa-response/five-paisa-response.component';
import { AliceBlueReponseComponent } from './alice-blue-reponse/alice-blue-reponse.component';
import { RedirectionComponent } from './redirection/redirection.component';

const routes: Routes = [
  { path: '', loadChildren: './home/home.module#HomeModule' },
  { path: 'zerodha', loadChildren: './zerodha/zerodha.module#ZerodhaModule' },
  { path: 'kotak', loadChildren: './kotak/kotak.module#KotakModule' },
  { path: 'edelweiss', loadChildren: './edelweiss/edelweiss.module#EdelweissModule' },
  { path: 'dhan', loadChildren: './dhan/dhan.module#DhanModule' },
  { path: 'bonds', loadChildren: './bonds/bonds.module#BondsModule' },
  { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule' },
  { path: 'track-mf', loadChildren: './track-mf/track-mf.module#TrackMfModule' },
  { path: 'generate-email', component: EmailVerifyComponent },
  { path: 'verified', component: VerifiedComponent },
  { path: 'feedback/:rating', component: FeedbackComponent },
  { path: 'refute', component: RefuteComponent },
  { path: 'get-account-info/:userId/:coupounCode/:accessToken', component: GetAccountInfoComponent },
  { path: 'icici-login/:userId/:accessToken/:editGoalId', component: IciciLoginComponent },
  { path: 'icici-response/:iciciUserId/:sessionToken', component: IciciResponseComponent },
  { path: 'icici-trades/:iciciUserId/:editGoalId', component: IciciTradesComponent },
  { path: 'alice-blue/:userId/:accessToken/:editGoalId', component: AliceBlueComponent },
  { path: '5paisa/:userId/:accessToken/:editGoalId', component: FivePaisaComponent },
  { path: '5paisa-response', component: FivePaisaResponseComponent },
  { path: 'alice-blue-response', component: AliceBlueReponseComponent },
  { path: 'advisory-fees', component: AdvisoryFeesComponent },
  { path: 'broker-login', component: BrokerLoginComponent },
  { path: 'redirect', component: RedirectionComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
