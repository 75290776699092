import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from "../shared/services/common/common.service";
import { ActivatedRoute } from '@angular/router';
import { IciciService } from "../shared/services/icici/icici.service";
import { environment } from '../../environments/environment';

declare let UIkit: any;

@Component({
  selector: 'app-icici-trades',
  templateUrl: './icici-trades.component.html',
  styleUrls: ['./icici-trades.component.scss']
})
export class IciciTradesComponent implements OnInit {

  @ViewChild('fundsAllocationForm', { static: false }) fundsAllocationForm: ElementRef;
  fundsAllocationUrl = `${environment.iciciAPI}apiuser/allocatefunds`;


  editGoalId: string;
  tradesDetails: any;
  totalMargin: number = 0;
  apiCalling: boolean = true;
  marketError: string;
  userInfo: any = {};
  userInitial: string = "AG";
  orderResponse: any = [];
  iciciUserInfo: any = { iciciUserId: "", marginInfo: {} };
  errMsg = "";
  allocateFundsObj = { AppKey: "", currentDate: "", checksum: "", iciciUserId: "", apiSession: "", product: "" };
  constructor(private commonService: CommonService, private router: ActivatedRoute, private iciciService: IciciService) { }


  ngOnInit() {
    this.getQuery();
    this.getUserDetails();
    setTimeout(() => this.getTradesDetails(), 1000);
    // setTimeout(() => this.getMarginDetails(), 1200);
  }

  getQuery() {
    this.router.params.subscribe((params) => {
      this.editGoalId = params.editGoalId ? params.editGoalId : this.editGoalId;
      this.iciciUserInfo.iciciUserId = params.iciciUserId ? params.iciciUserId : this.iciciUserInfo.iciciUserId;
    });
  }

  getUserDetails() {
    this.commonService.getUserInfo().subscribe((res) => {
      this.commonService.setUserInfo(res);
      this.userInfo = res;
      this.userInitial = this.userInfo ? `${this.userInfo.givenName[0]}${this.userInfo.familyName[0]}` : "AG";
      this.userInitial = this.userInitial.slice(0, 2).toUpperCase().trim();
    });
  }

  getMarginDetails() {
    this.iciciService.getMarginValue(this.iciciUserInfo.iciciUserId).subscribe((res) => {
      if (res && res.statusCode) {
        this.iciciUserInfo.marginInfo = res.data;
      }
    })
  }

  getTradesDetails() {
    if (this.editGoalId) {
      this.apiCalling = true;
      let reqBody = { editGoalId: this.editGoalId };
      this.iciciService.getTradesDetails(reqBody).subscribe((res) => {
        this.apiCalling = !this.apiCalling;
        console.log(res);
        this.tradesDetails = res && res.length ? res : [];
        this.updatedTradesInfo();
        this.getPortfolio();
      }, (err) => {
        this.marketError = err.error && err.error.error ? err.error.error.message : "Unable to get your trades";
        this.apiCalling = !this.apiCalling;
      });
    } else {
      location.href = "/refute?statusCode=0&msg=Invalid Goal Details&title=Invalid";
    }
  }

  updatedTradesInfo() {
    let trades = [];
    if (this.tradesDetails && this.tradesDetails.length) {
      trades = this.tradesDetails.filter((t) => !t.arcadiaId);
      this.tradesDetails.map((t) => {
        t.tradeCost = t.transaction_type == 'BUY' ? t.price * t.quantity : -1 * (t.price * t.quantity);
        return t;
      });
    }
    this.totalMargin = trades ? trades.reduce((a, b) => a + (b.price * b.quantity), 0) : this.totalMargin;
  }

  convertToInt(amount) {
    return Number(amount);
  }

  executeTrades() {
    let trades = this.tradesDetails.filter((t) => !t.arcadiaId);
    this.iciciService.executeTrades(trades).subscribe((res) => {
      if (res && res.statusCode) {
        this.orderResponse = res.orders;
      } else {
        this.errMsg = res && res.msg ? res.msg : "Error while placing orders";
        this.orderResponse.push({ ErrMessage: this.errMsg });
        UIkit.modal('#relogin').show();
      }
      setTimeout(() => this.getTradesDetails(), 2000);
    }, (err) => {
      console.log(err);
      this.orderResponse.push({ ErrMessage: "Error while placing orders" });
    })
  }

  canExecuteTrades() {
    let trades = this.tradesDetails.filter((t) => !t.arcadiaId);
    return trades && trades.length ? true : false;
  }


  // getOrderStatus() {
  //   this.iciciService.getOrderStatus().subscribe((res) => {
  //     console.log(res);
  //   });
  // }

  getPortfolio() {
    this.iciciService.getPortfolio().subscribe((res) => {
      console.log(res);
    });
  }


  redirectToLogin() {
    // UIkit.modal('#relogin').hide();
    let accessToken = this.commonService.getToken();
    let editGoalInfo: any = localStorage.getItem("editGoalId");
    editGoalInfo = editGoalInfo ? JSON.parse(editGoalInfo) : "";
    let userId = this.commonService.getUserId();
    location.href = `/icici-login/${userId}/${accessToken}/${editGoalInfo.editGoalId}`
  }


  allocateFunds() {
    this.iciciService.allocateFunds().subscribe((res: any) => {
      if (res && res.statusCode) {
        this.allocateFundsObj = res.allocationInfo;
        setTimeout(() => this.navigateIcici(), 1000);
      }
    })
  }

  navigateIcici() {
    this.fundsAllocationForm.nativeElement.submit();
  }

}
