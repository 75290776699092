import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from "moment";



declare let $: any;

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {


  @Input("singleDatePicker") singleDatePicker: boolean;
  @Input("minDate") minDate: string = null;
  @Input("maxDate") maxDate: string = null;
  @Output() selectedDateRange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => this.initDataPicker(), 1000);
  };


  initDataPicker() {
    this.maxDate = moment(this.maxDate).format("MM/DD/YYYY").toString();
    let startDate = this.maxDate ? this.maxDate : moment("MM/DD/YYYY").toString();
    $('#datePicker').daterangepicker({
      singleDatePicker: this.singleDatePicker,
      startDate,
      minDate: this.minDate,
      maxDate: this.maxDate,
      autoApply: true,
      drops: "auto",
      showDropdowns: true,
    }, (start, end, label) => {
      let selectedDates = { start, end };
      this.selectedDateRange.emit(selectedDates);
      // console.log('New date range selected: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' (predefined range: ' + label + ')');
    });
  }


}
