import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService, SmallDotsComponent } from '../shared/shared.module';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { environment } from "../../environments/environment";
// import {FormsModule,ReactiveFormsModule} from '@angular/forms';



@Component({
  selector: 'app-email-verify',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.scss']
})
export class EmailVerifyComponent implements OnInit {

  queryParams: any = { email: "", deviceType: "", publicIp: "", isWeb: false }
  isMailSend: boolean = false;
  emailEdit: boolean = false;
  mailSenderSub: any;
  emailForm: any;
  clientIp: any;
  mailerAPICalled: number = 0;
  emailLimit = environment.emailLimit;
  constructor(private route: ActivatedRoute, private commonService: CommonService, private fb: FormBuilder) {
    this.getIp();
  }

  ngOnInit() {
    this.getParams();
    this.initForm();
  }

  initForm() {
    this.emailForm = this.fb.group({
      email: [this.queryParams.email, [Validators.required, Validators.email]]
    })
  }

  get f() { return this.emailForm.controls; }

  getIp() {
    this.commonService.getClientIp().subscribe((res: any) => {
      this.clientIp = res.ip;
      this.queryParams.publicIp = this.clientIp;
    });
  }


  getParams() {
    this.route.queryParams.subscribe((params) => {
      //Since request params are in readonly state deep clone object
      this.queryParams = JSON.parse(JSON.stringify(params));
      this.queryParams.isWeb = this.queryParams.isWeb ? this.queryParams.isWeb : "";
      this.queryParams.publicIp = this.clientIp;
      this.getReferralUrl();
      setTimeout(() => this.sendEmail(), 3000);
    });
  }

  getReferralUrl() {
    let referralUrl = JSON.parse(JSON.stringify(this.queryParams));
    delete referralUrl.email;
    delete referralUrl.deviceType;
    delete referralUrl.publicIp;
    delete referralUrl.isWeb;
    this.queryParams.referralUrl = referralUrl && Object.entries(referralUrl).length ? this.commonService.convertObjectToQuery(referralUrl) : "";
  }

  sendEmail() {
    this.commonService.sendUserEmail(this.queryParams.email, this.queryParams.deviceType, this.queryParams.publicIp, this.queryParams.referralUrl).subscribe((res) => {
      this.isMailSend = true;
      setTimeout(() => this.processEmailVerified(), 10000);
    })
  }

  processEmailVerified() {
    this.mailSenderSub = setInterval(() => this.isEmailVerified(), 5000);
  }

  isEmailVerified() {
    if (this.mailerAPICalled < this.emailLimit) {
      this.mailerAPICalled++;
      this.commonService.isEmailVerified(this.queryParams.email).subscribe((data: any) => {
        if (data.statusCode) {
          location.href = `/verified?accessToken=${data.validationCode}&isWeb=${this.queryParams.isWeb}`;
        }
      });
    } else {
      this.clearTimer();
    }
  }

  clearTimer() {
    clearInterval(this.mailSenderSub);
  }

  resendEmail() {
    this.queryParams.email = this.emailForm.get('email').value;
    this.sendEmail();
    this.clearTimer();
    this.emailEdit = !this.emailEdit;
    this.isMailSend = !this.isMailSend;
    this.mailerAPICalled = 0;
  }

  editEmail() {
    this.emailEdit = !this.emailEdit;
  }

}
