import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-small-dots',
  templateUrl: './small-dots.component.html',
  styleUrls: ['./small-dots.component.scss']
})
export class SmallDotsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
