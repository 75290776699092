import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
    name: "dateFormater",
})

export class DateFormater implements PipeTransform {

    constructor() { }

    transform(date: any, dateFormat: string = "L"): any {
        return moment(date).format(dateFormat);
    }
}
