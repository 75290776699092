import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { EmailVerifyComponent } from './email-verify/email-verify.component';
import { VerifiedComponent } from './verified/verified.component';
import { RefuteComponent } from './refute/refute.component';
import { FixedDecimal } from "./shared/pipe/fixedDecimal";
import { GetAccountInfoComponent } from './get-account-info/get-account-info.component';
import { IciciLoginComponent } from './icici-login/icici-login.component';
import { IciciResponseComponent } from './icici-response/icici-response.component';
import { IciciTradesComponent } from './icici-trades/icici-trades.component';
import { AdvisoryFeesComponent } from './advisory-fees/advisory-fees.component';
import { BrokerLoginComponent } from './broker-login/broker-login.component';
import { AliceBlueComponent } from './alice-blue/alice-blue.component';
import { AliceBlueReponseComponent } from './alice-blue-reponse/alice-blue-reponse.component';
import { RedirectionComponent } from './redirection/redirection.component';
import { FivePaisaComponent } from './five-paisa/five-paisa.component';
import { FivePaisaResponseComponent } from './five-paisa-response/five-paisa-response.component';
import { FeedbackComponent } from './feedback/feedback.component';

const component = [AppComponent, NotFoundComponent, EmailVerifyComponent, VerifiedComponent, FeedbackComponent, RefuteComponent, GetAccountInfoComponent, IciciLoginComponent, IciciResponseComponent, IciciTradesComponent, AdvisoryFeesComponent, BrokerLoginComponent, AliceBlueComponent, AliceBlueReponseComponent, RedirectionComponent, FivePaisaComponent, FivePaisaResponseComponent];

const pipe = [FixedDecimal];

@NgModule({
  declarations: [...component,],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  exports: [SharedModule]
})
export class AppModule { }
export { SharedModule }
