<div class="uk-section uk-section-muted uk-section-medium">
    <div class="uk-container uk-container-xlarge">
        <div class="uk-tile uk-tile-muted uk-padding-remove uk-text-center uk-padding-remove-top">
            <div class="uk-container uk-margin-top">
                <div class="uk-flex uk-flex-center">
                    <div class="uk-width-large uk-flex uk-flex-column uk-flex-center">
                        <div class="uk-text-left back-arrow">
                            <span (click)="cancelPayment()" uk-icon="icon: close"></span>
                        </div>
                        <div class="uk-margin-small">
                            <div class="uk-card uk-card-default uk-card-small uk-card-body round-coner why-account-info uk-animation-slide-left-small">
                                <div class="uk-flex uk-flex-between">
                                    <div class="">Why do we need to provide bank info?</div>
                                    <div class="">
                                        <span uk-tooltip="title: As per SEBI guidelines/regulations, the user registered on the app i.e availing investment advice and the bank account name should match.; pos: left" uk-icon="info"></span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isSinglePaymentValid && dropPenny && !paymentBlock && !tpvBankError" class="uk-margin-small-top">
                                <span class="uk-label uk-label-warning">Currently we support only
                                    {{ bankForm.get("payType").value | uppercase }}</span>
                            </div>
                        </div>
                        <div *ngIf="userAccountInfo.userAccountC.accountVerifiedCount == 1 && f.accountNo.value == null" class="uk-margin-small-bottom">
                            <span class="uk-label uk-label-warning">1 attempt left</span>
                        </div>
                        <div *ngIf="!dropPenny && !paymentBlock && !tpvBankError" class="uk-card uk-card-default round-coner uk-card-body uk-padding-small uk-animation-slide-left-small">
                            <form [formGroup]="bankForm" (ngSubmit)="pennyDrop()">
                                <div *ngIf="!isSinglePaymentValid" class="uk-text-left">
                                    <div>
                                        <label for="accountNo">Payment Using</label>
                                    </div>
                                    <div>
                                        <label>
                                            <input formControlName="payType" class="uk-radio" type="radio"
                                                value="NETBANKING" name="payType" checked>
                                            <span class="uk-margin-small-left">Internet Banking </span>
                                        </label>
                                        <label>
                                            <input formControlName="payType" class="uk-radio" type="radio" value="UPI"
                                                name="payType">
                                            <span class="uk-margin-small-left">UPI</span>
                                        </label>
                                    </div>
                                </div>
                                <div *ngIf="f.payType.value == 'NETBANKING'" class="uk-margin uk-text-left ">
                                    <div>
                                        <label for="accountNo">Search Bank</label>
                                    </div>
                                    <div class="uk-inline uk-width-1-1">
                                        <angular2-multiselect [data]="tvpBankList" [(ngModel)]="selectedItems"
                                            [ngModelOptions]="{standalone: true}" [settings]="dropdownSettings"
                                            (onOpen)="openDropDown()"
                                            (onSelect)="onBankSelect($event)" (onDeSelectAll)="OnBankDeSelect($event)">
                                        </angular2-multiselect>
                                    </div>
                                </div>
                                <div class="uk-margin uk-text-left">
                                    <div>
                                        <label for="accountNo">Account No</label>
                                    </div>
                                    <div class="uk-inline uk-width-1-1">
                                        <input maxlength="18" type="text" [ngClass]="{'uk-form-danger': f.accountNo.dirty && f.accountNo.touched && f.accountNo.errors}" formControlName="accountNo" class="uk-input" placeholder="XXXXXXXXXX2846">
                                        <a *ngIf="f.accountNo.disabled" href="javascript:void(0)" uk-tooltip="Your last bank account is verified" class="uk-form-icon uk-form-icon-flip" uk-icon="icon: info"></a>
                                    </div>
                                    <span *ngIf="f.accountNo.dirty && f.accountNo.touched && f.accountNo.errors" class="uk-text-meta uk-text-danger">Invalid Account No</span>
                                </div>
                                <div class="uk-margin uk-text-left">
                                    <div>
                                        <label for="accountNo">IFSC Code</label>
                                    </div>
                                    <div class="uk-inline uk-width-1-1">
                                        <input autocapitalize="characters" [ngClass]="{'uk-form-danger': f.ifscCode.dirty && f.ifscCode.touched && f.ifscCode.errors}" formControlName="ifscCode" class="uk-input" type="text" id="ifscCode" placeholder="AAAA-888892">
                                        <a *ngIf="f.ifscCode.disabled" href="javascript:void(0)" uk-tooltip="Your last ifsc code is verified" class="uk-form-icon uk-form-icon-flip" uk-icon="icon: info"></a>
                                    </div>

                                    <span *ngIf="f.ifscCode.dirty && f.ifscCode.touched && f.ifscCode.errors" class="uk-text-meta uk-text-danger">Invalid IFSC Code</span>
                                </div>

                                
                                <div *ngIf="isSinglePaymentValid" class="">
                                    <div class="uk-text-left">
                                        <label for="accountNo">Payment Using</label>
                                    </div>
                                    <span class="uk-label uk-label-warning">Currently we support only
                                        {{ bankForm.get("payType").value | uppercase }}</span>
                                </div>
                                <div class="uk-margin uk-margin-top uk-grid-small uk-grid">
                                    <div>
                                        <p class="uk-text-meta"> Your information is secured with </p>
                                    </div>
                                    <div class="uk-flex">
                                        <div class="uk-flex-first">
                                            <span class="uk-text-muted" uk-icon="check"></span>
                                            <span class="uk-text-meta uk-text-muted">Bank Grade Security</span>
                                        </div>
                                        <div class="uk-margin-left">
                                            <span class="uk-text-muted" uk-icon="check"></span>
                                            <span class="uk-text-meta uk-text-muted">512-bit Encryption</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="uk-margin-large">
                                    <div class="uk-text-center">
                                        <button [disabled]="bankForm.invalid" class="uk-button uk-width-1-2@l uk-text-center uk-button-primary tavaga-btn" type="submit">Next
                                            <div *ngIf="apiCall" uk-spinner class="uk-margin-small-left"></div>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>


                        <div *ngIf="dropPenny && !paymentBlock && !tpvBankError" class="uk-height-large uk-card uk-card-default round-coner uk-card-body uk-margin-top uk-padding-small uk-animation-slide-left-small">
                            <div class="uk-margin-top">
                                <div class="uk-flex uk-flex-center">
                                    <div class="uk-text-meta">
                                        <span>Transfering ₹1 to your acount </span>
                                    </div>
                                </div>
                                <div class="uk-flex uk-flex-inline uk-flex-between uk-margin-small-top">
                                    <div>
                                        <img data-src="./assets/img/Logo.png" width="60px" height="60px" alt="" uk-img>
                                    </div>
                                    <div class="uk-padding-small uk-padding-remove-top">
                                        <img *ngIf="!pennyBankObj.isPennyDrop" data-src="./assets/gif/gif-maker.gif" width="50px" height="50px" alt="" uk-img>
                                        <img *ngIf="pennyBankObj.isPennyDrop" class="uk-margin-small-top" data-src="./assets/svg/right.svg" width="45px" height="40px" alt="" uk-img>
                                    </div>
                                    <div>
                                        <img data-src="./assets/svg/green-sweater.svg" width="60px" height="60px" alt="" uk-img>
                                    </div>
                                </div>
                                <div *ngIf="pennyErrorMsg && !pennyBankObj.isRejected" class="uk-text-center">
                                    <p class="uk-text-danger uk-padding-small">
                                        {{pennyErrorMsg}}
                                    </p>
                                    <p class="uk-padding-small uk-padding-remove-top uk-margin-remove">
                                        Incase of any queries
                                        <a href="mailto:support@tavaga.com?Subject=Account already linked - User id {{userId}} / {{userAccountInfo.phone}}" target="_top">Send Mail</a>
                                    </p>
                                    <div class="uk-text-center">
                                        <span>OR</span>
                                        <p class="uk-padding-small uk-padding-remove-top uk-margin-remove" (click)="editAccNo()">Edit Account No
                                            <a href="javascript:void(0)" class="uk-icon-link" uk-icon="pencil"></a>
                                        </p>
                                    </div>
                                </div>
                                <div *ngIf="pennyBankObj.isRejected">
                                    <div class="uk-card uk-card-default uk-card-body">
                                        <h3 class="uk-card-title">
                                            Alert
                                            <span uk-icon="warning"></span>
                                        </h3>
                                        <p class="uk-text-danger">Fail to validate Account No, Kindly provide valid bank account
                                        </p>
                                        <p (click)="editAccNo()">Edit Account No
                                            <a href="javascript:void(0)" class="uk-icon-link" uk-icon="pencil"></a>
                                        </p>
                                    </div>

                                </div>
                            </div>

                            <div *ngIf="!pennyErrorMsg && !pennyBankObj.isRejected" class="uk-margin-large-top">
                                <div class="uk-flex uk-flex-center">
                                    <div class="uk-text-meta">
                                        <span>Validating with Bank</span>
                                    </div>
                                </div>
                                <div class="uk-flex uk-flex-inline uk-flex-between uk-margin-small-top">
                                    <div>
                                        <img data-src="./assets/img/Logo.png" width="60px" height="60px" alt="" uk-img>
                                    </div>
                                    <div class="uk-padding-small uk-padding-remove-top">
                                        <img *ngIf="!pennyBankObj.isBankVerified" data-src="./assets/gif/gif-maker.gif" width="50px" height="50px" alt="" uk-img>
                                        <img *ngIf="pennyBankObj.isBankVerified" class="uk-margin-small-top" data-src="./assets/svg/right.svg" width="47px" height="40px" alt="" uk-img>
                                    </div>
                                    <div>
                                        <img data-src="./assets/svg/bank.svg" class="bank-ico" alt="" uk-img>
                                    </div>

                                </div>
                                <!-- <button (click)="redirectToPayment()"
                                    *ngIf="pennyBankObj.isPennyDrop && pennyBankObj.isBankVerified && !pennyBankObj.isRejected"
                                    class="uk-button uk-width-1-2@l uk-text-center uk-button-primary uk-margin-large-top"
                                    type="submit">Pay
                                    Now
                                </button> -->
                                <div>
                                    <app-razor-pay [userId]="userId" [coupounCode]="coupounCode" [isWeb]="isWeb" *ngIf="pennyBankObj.isPennyDrop && pennyBankObj.isBankVerified && !pennyBankObj.isRejected">
                                    </app-razor-pay>
                                </div>
                            </div>


                        </div>

                        <div *ngIf="paymentBlock && !tpvBankError" class="uk-height-large uk-card round-coner uk-card-default uk-card-body uk-margin-top">
                            <div class="uk-flex uk-flex-center">
                                <div class="uk-text-meta">
                                    <span>Account blocked!</span>
                                    <p class="uk-margin-small-top">Your account could not be validated even after 2 attempts. Kindly mail us valid account info along with cancelled cheque</p>
                                    <span>
                                        <a href="mailto:support@tavaga.com?Subject=Bank info mismatch - user I'd {{userId}} - {{userAccountInfo.phone}}&body=Please%20provide%20valid%20bank%20details%20along%20with%20cancelled%20cheque."
                                            class="uk-button uk-button-default" href="">Send Mail</a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="tpvBankError" class="uk-height-large uk-card round-coner uk-card-default uk-card-body uk-margin-top">
                            <div class="uk-flex uk-flex-center">
                                <div class="uk-text-meta">
                                    <span>Currenlty we don't support other than this bank</span>
                                    <p class="uk-margin-small-top">Kindly mail us the Bank info</p>
                                    <span>
                                        <a href="mailto:support@tavaga.com?Subject=TPV not support for TavagaId {{userId}} / {{userAccountInfo.phone}}"
                                            class="uk-button uk-button-default" href="">Send Mail</a>
                                    </span>
                                    <p (click)="editBank()">Edit Bank
                                        <a href="javascript:void(0)" class="uk-icon-link" uk-icon="pencil"></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- This is the modal -->
<div id="confirmAccount" uk-modal>
    <div class="uk-modal-dialog uk-width-1-4@l round-corner uk-margin-auto-vertical uk-animation-toggle">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header uk-padding-small">
            <h5 class="uk-modal-title">Need your attention!</h5>

        </div>
        <div class="uk-modal-body uk-padding-small">
            <p class="uk-text-lighter">Please review the account details provided. We will verify the account by crediting ₹1 to your account</p>
            <div class="uk-background-muted uk-text-center uk-text-middle account-number-border">
                <span>Account No</span>
                <span class="uk-margin-small-left uk-text-emphasis">{{ bankForm.get('accountNo').value }}</span>
            </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
            <button class="uk-button uk-button-default uk-modal-close" type="button">Cancel</button>
            <button (click)="callPenny()" class="uk-button uk-button-primary uk-margin-small-left" type="button">Confirm</button>
        </div>
    </div>
</div>