import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../common/common.service";
export class IciciService {
    constructor(http, commonService) {
        this.http = http;
        this.commonService = commonService;
    }
    getICICIChecksum() {
        return this.http.get('icicis/getChecksum/');
    }
    updateBroker(reqBody) {
        let userId = this.commonService.getUserId();
        return this.http.put(`users/${userId}/updatebrokerinfo`, reqBody);
    }
    getTradesDetails(reqBody) {
        reqBody.userId = this.commonService.getUserId();
        return this.http.post(`users/basketOrderWithEditGoalId`, reqBody);
    }
    getMarginValue(iciciUserId) {
        return this.http.get(`icicis/getISECMargin/${iciciUserId}`);
    }
    executeTrades(orderInfo) {
        return this.http.post(`icicis/executeTrades`, orderInfo);
    }
    getOrderStatus() {
        return this.http.get(`icicis/getOrderUpdate`);
    }
    getPortfolio() {
        return this.http.get(`icicis/getPortfolio`);
    }
    allocateFunds() {
        return this.http.get(`icicis/allocateFunds`);
    }
}
IciciService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IciciService_Factory() { return new IciciService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CommonService)); }, token: IciciService, providedIn: "root" });
