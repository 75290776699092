import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
const subject = new Subject();
export class BondsService {
    constructor(http, router, activatedRoute) {
        this.http = http;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.bondsSubject = subject;
    }
    getBondsSubject() {
        return this.bondsSubject.asObservable();
    }
    senBondsSubject(action, data) {
        this.bondsSubject.next({ action, data });
    }
    setBondName(bondName) {
        return bondName.toLowerCase().replace(/^\w|\s\w/g, (letter) => {
            return letter.toUpperCase();
        });
    }
    getMarketplace() {
        return this.http.get('bond/marketplace');
    }
    getBondList(reqBody) {
        return this.http.post('bond/bondList', reqBody);
    }
    getBondData(instrumentId) {
        return this.http.get(`bond/bondData/${instrumentId}`);
    }
    getQuoteData(instrumentId, quoteType) {
        return this.http.get(`bond/getQuoteData/${instrumentId}/${quoteType}`);
    }
    getUserInfo() {
        return this.http.get('bond/getUserInfo');
    }
    updateDematAccount(reqBody) {
        return this.http.post('bond/updateDematAccount', reqBody);
    }
    createHarmoneyUser() {
        return this.http.get('bond/createUser');
    }
    placeOrder(reqBody) {
        return this.http.post('bond/placeOrder', reqBody);
    }
    getPendingStatus(instrumentId) {
        return this.http.get(`bond/getPendingStatus/${instrumentId}`);
    }
    getUserPortfolio() {
        return this.http.get('bond/getHoldings');
    }
    isPaymentDone(paymentId) {
        return this.http.get(`bond/user-orderStatus/${paymentId}`);
    }
}
BondsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BondsService_Factory() { return new BondsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i2.ActivatedRoute)); }, token: BondsService, providedIn: "root" });
