<div class="uk-container uk-container-large uk-height-large">
    <div class="uk-text-center">
        <div uk-spinner="ratio: 3"></div>
    </div>

    <form style="display: none;" method="post" [action]="loginUrl" #iciciLogin name="icici">
        <table border="1">
            <tbody>
                <input type="hidden" name="AppKey" [value]="iciciChecksumDetails.appKey">
                <input type="hidden" name="time_stamp" [value]="iciciChecksumDetails.currentDate">
                <input type="hidden" name="Checksum" [value]="iciciChecksumDetails.icicChecksum">
            </tbody>
        </table>
    </form>
</div>