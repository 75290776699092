import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from "../shared/services/common/common.service";
import { ActivatedRoute, Router } from '@angular/router';
import { IciciService } from "../shared/services/icici/icici.service";

@Component({
  selector: 'app-icici-response',
  templateUrl: './icici-response.component.html',
  styleUrls: ['./icici-response.component.scss']
})
export class IciciResponseComponent implements OnInit {

  iciciUserId: string;
  sessionToken: string;
  isWeb: any;
  constructor(private commonService: CommonService, private activatedRoute: ActivatedRoute, private router: Router, private iciciService: IciciService) { }


  ngOnInit() {
    this.getQuery();
    this.checkForWeb();
    this.isBrokerLink();
  }


  getQuery() {
    this.activatedRoute.params.subscribe((params) => {
      this.iciciUserId = params.iciciUserId ? params.iciciUserId : this.iciciUserId;
      this.sessionToken = params.sessionToken ? params.sessionToken : this.sessionToken;
    });
  }

  isBrokerLink() {
    let editGoalId: any = localStorage.getItem("editGoalId");
    if (editGoalId) {
      editGoalId = JSON.parse(editGoalId);
      let noOfDays = this.commonService.getDateDifference(editGoalId.dateAt, new Date(), "days");
      if (noOfDays == 0 && editGoalId.editGoalId) this.processForTrades(editGoalId.editGoalId)
      else this.updateBroker();
    } else {
      this.updateBroker();
    }
  }

  processForTrades(editGoalId: string) {
    location.href = `/icici-trades/${this.iciciUserId}/${editGoalId}`;
  }

  checkForWeb() {
    this.isWeb = this.commonService.isWebRequest();
  }

  updateBroker() {
    let reqBody = {
      brokerId: this.iciciUserId,
      brokerName: "ICICISEC",
      zerodhaAccessTokenId: this.sessionToken
    }
    this.iciciService.updateBroker(reqBody).subscribe((res) => {
      if (res && res.statusCode) {
        location.href = this.isWeb ? `/verified?isWeb=${this.isWeb}` : `/verified`;
      } else {
        let msg = res.message ? res.message : "Something went Wrong!";
        location.href = this.isWeb ? `/refute?statusCode=0&msg=${msg}&isWeb=${this.isWeb}` : `/refute?statusCode=0&msg=${msg}`;
      }
    }, (err) => {
      console.log(err);
      let msg = "something went wrong!";
      location.href = this.isWeb ? `/refute?statusCode=0&msg=${msg}&isWeb=${this.isWeb}` : `/refute?statusCode=0&msg=${msg}`;
    });
  }

}


