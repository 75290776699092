import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../common/common.service";
const subject = new Subject();
export class DashboardService {
    constructor(http, router, activatedRoute, commonService) {
        this.http = http;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.commonService = commonService;
        this.dashboardSubject = subject;
    }
    getDashboardSubject() {
        return this.dashboardSubject.asObservable();
    }
    sendDashboardSubject(action, data) {
        this.dashboardSubject.next({ action, data });
    }
    validatePin(postBody) {
        return this.http.post(`users/validatePin`, postBody);
    }
    logout(postBody) {
        return this.http.post(`users/logout`, postBody);
    }
    getUserDetails() {
        return this.http.get(`users/currentUser`);
    }
    getSavedUserDetails() {
        let userInfo = localStorage.getItem('userInfo');
        userInfo = userInfo ? JSON.parse(userInfo) : "";
        return userInfo;
    }
    getUserId() {
        return this.commonService.getUserId();
    }
    getPortfolio() {
        return this.http.get(`users/${this.getUserId()}/portfolio`);
    }
    getGoals() {
        return this.http.get(`users/${this.getUserId()}/goals`);
    }
    getMoneyTransferDay() {
        return this.http.get(`users/${this.getUserId()}/bankDetails/moneyTransferDay`);
    }
    getNextTradingDay() {
    }
    getCompletePortfolio() {
        return this.http.get(`users/getCompletePortfolio`);
    }
    getGoalDetail(goalId) {
        return this.http.get(`goals/${goalId}`);
    }
    sendOTP(reqBody) {
        return this.http.put(`users/${this.getUserId()}/sendOtpForPhone`, reqBody);
    }
    validateOTP(reqBody) {
        return this.http.post(`users/${this.getUserId()}/verifyOtpForPhone`, reqBody);
    }
    updateUserInfo(reqBody) {
        reqBody.id = this.getUserId();
        return this.http.put(`users/${reqBody.id}`, reqBody);
    }
    savePANInfo(reqBody) {
        return this.http.put(`users/${this.getUserId()}/userKyc`, reqBody);
    }
    putRiskLevel(reqBody) {
        return this.http.put(`users/${this.getUserId()}/riskLevel`, reqBody);
    }
    storeRiskProfile(reqBody) {
        return this.http.post(`riskProfiles/${this.getUserId()}/storeRiskProfile`, reqBody);
    }
    getMonthlyAmount(reqBody) {
        let riskLevel = this.getSavedUserDetails();
        riskLevel = riskLevel && riskLevel.risk.riskLevel ? riskLevel.risk.riskLevel : 5;
        return this.http.post(`risks/${riskLevel}/goalEstimate`, reqBody);
    }
    calculateTTG(reqBody) {
        let riskLevel = this.getSavedUserDetails();
        reqBody.riskLevel = riskLevel && riskLevel.risk.riskLevel ? riskLevel.risk.riskLevel : 5;
        return this.http.post(`goals/calculateTTG`, reqBody);
    }
    getAllBrokerList() {
        return this.http.get(`brokers/getAllBrokers`);
    }
}
DashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardService_Factory() { return new DashboardService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i2.ActivatedRoute), i0.ɵɵinject(i3.CommonService)); }, token: DashboardService, providedIn: "root" });
