<div class="uk-container uk-container-large uk-margin-top-small">
    <div class="uk-card uk-card-default uk-card-body uk-grid-small uk-padding-small" style="z-index: 980;"
        uk-sticky="bottom: #offset" uk-grid>
        <div class="uk-width-1-2">
            <img data-src="https://tavaga-assets.s3.ap-south-1.amazonaws.com/mailer/icici_logo.png" alt="ICICI-Tavaga"
                uk-img>
        </div>
        <div class="uk-width-1-2">
            <div class="uk-align-right uk-flex-inline">
                <div class="user-circle">{{ userInitial }}</div>
                <div class="small-padding">
                    <span>{{ userInfo && userInfo.brokerId }}</span>
                </div>
            </div>

        </div>
    </div>

    <hr class="uk-margin-remove-top">

    <div *ngIf="marketError" class="uk-padding">
        <label class="uk-text-danger uk-text-small">{{marketError}}</label>
    </div>

    <div *ngIf="apiCalling" class="uk-text-center">
        <div uk-spinner="ratio: 3"></div>
    </div>

    <div *ngIf="tradesDetails">
        <div class="uk-text-center">
            <span class="uk-text-muted uk-text-small">Make sure you have ₹{{totalMargin | fixedDecimal}} in your equity
                account</span>
        </div>
        <!-- <div class="uk-text-center"> -->
        <!-- <span class="uk-text-normal">Available Margin </span> -->
        <!-- <span [ngClass]="convertToInt(iciciUserInfo.marginInfo.IsecMargin) >= convertToInt(totalMargin) ? 'uk-text-success uk-text-emphasis' :  -->
        <!-- 'uk-text-danger uk-text-emphasis'"> ₹{{iciciUserInfo.marginInfo.IsecMargin}}</span> -->
        <!-- </div> -->
        <ul class="uk-list uk-list-divider">
            <li *ngFor="let orderDetails of tradesDetails;let i = index">
                <div>
                    <div class="uk-grid-small" uk-grid>
                        <div class="uk-width-1-2">
                            <div class="uk-flex-inline">
                                <div class="small-padding">
                                    <span
                                        [ngClass]="orderDetails.arcadiaId ? 'uk-label uk-label-warning' : orderDetails.transaction_type == 'BUY' ? 'uk-label uk-label-success' : 'uk-label uk-label-danger'">
                                        {{ orderDetails.arcadiaId ? "PLACED" : orderDetails.transaction_type }}
                                    </span>
                                </div>
                                <div class="small-padding uk-flex-inline">
                                    <span class="uk-text-muted uk-text-small">Qty
                                        <span class="uk-text-emphasis uk-text-small"> {{ orderDetails.quantity }}</span>
                                    </span>
                                </div>
                            </div>

                            <div class="small-padding uk-padding-remove-top">
                                <span class="uk-text-normal">{{ orderDetails.tradingsymbol }} <span
                                        class="uk-text-small">NSE</span></span>
                            </div>
                        </div>
                        <div class="uk-width-1-2">
                            <div class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-2">
                                    <div class="small-padding uk-flex-inline">
                                        <span class="uk-text-muted uk-text-small">LTP
                                            <span class="uk-text-emphasis uk-text-small">
                                                {{ orderDetails.price }}</span>
                                        </span>
                                    </div>
                                    <div class="small-padding uk-padding-remove-top">
                                        <span class="uk-text-muted uk-text-small">₹
                                            <span
                                                class="uk-text-emphasis uk-text-small">{{ orderDetails.tradeCost | fixedDecimal  }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="uk-width-1-2">
                                    <div class="small-padding">
                                        <label class="uk-text-normal">{{ orderDetails.order_type }}</label>
                                    </div>
                                    <div class="small-padding uk-padding-remove-top">
                                        <label class="uk-text-lighter">DAY</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="uk-padding-small uk-padding-remove-top uk-padding-remove-bottom uk-grid-margin uk-first-column uk-margin-remove-top">
                            <span
                                [ngClass]="orderResponse[i] && orderResponse[i].ErrMessage ? 'uk-text-small uk-text-danger' : 'uk-text-success uk-text-success'">{{ orderResponse[i] && orderResponse[i].ErrMessage ? orderResponse[i].ErrMessage : orderResponse[i] ? "Order Placed" : "" }}</span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <div class="place-order">
            <button [disabled]="!canExecuteTrades() || apiCalling" (click)="executeTrades()"
                class="uk-button uk-button-primary round-coner">Place</button>

            <!-- <button (click)="allocateFunds()" class="uk-button uk-button-primary round-coner">Allocate Funds</button> -->

        </div>
    </div>
</div>


<!-- This is the modal -->
<div id="relogin" uk-modal='{"esc-close":false,"bg-close":false}'>
    <div
        class="uk-modal-dialog uk-modal-body uk-width-1-4@l round-corner uk-margin-auto-vertical uk-animation-toggle uk-animation-shake">
        <h3 class="uk-text-center uk-text-lead uk-text-danger">{{errMsg}}</h3>
        <div class="uk-text-center">
            <button class="uk-button uk-button-default uk-button-secondary" (click)="redirectToLogin()"
                type="button">Relogin</button>
        </div>
    </div>
</div>

<form style="display: none;" method="post" [action]="fundsAllocationUrl" #fundsAllocationForm name="fundsAllocation">
    <table border="1">
        <tbody>
            <input type="hidden" name="AppKey" [value]="allocateFundsObj.AppKey">
            <input type="hidden" name="time_stamp" [value]="allocateFundsObj.currentDate">
            <input type="hidden" name="checksum" [value]="allocateFundsObj.checksum">
            <input type="hidden" name="idirect_userid" [value]="allocateFundsObj.iciciUserId">
            <input type="hidden" name="SessionToken" [value]="allocateFundsObj.apiSession">
            <input type="hidden" name="PRODUCT" [value]="allocateFundsObj.product">
        </tbody>
    </table>
</form>