import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { CommonService, moment } from "../shared/shared.module";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-five-paisa',
  templateUrl: './five-paisa.component.html',
  styleUrls: ['./five-paisa.component.scss']
})
export class FivePaisaComponent implements OnInit {

  userId: string = "";
  accessToken: string = "";
  editGoalId: string = "";
  loginUrl = environment.five5paisaLogin;
  queryParams: any;

  constructor(private commonService: CommonService, private router: ActivatedRoute) { }


  ngOnInit() {
    this.getQuery();
    this.setToken();
    this.setEditGoalId();
    this.redirectTo5Paisa();
  }

  getQuery() {
    this.router.params.subscribe((params) => {
      this.userId = params.userId ? params.userId : this.userId;
      this.accessToken = params.accessToken ? params.accessToken : this.accessToken;
      this.editGoalId = params.editGoalId ? params.editGoalId : this.editGoalId;
    });

    this.router.queryParams.subscribe((params) => {
      this.queryParams = params;
      this.queryParams && this.queryParams.isWeb && this.commonService.setLocalStorage('isWeb', true);
    })
  }

  setToken() {
    let tokenObj = {
      id: this.accessToken,
      userId: this.userId,
    }
    this.commonService.setToken(tokenObj);
  }

  setEditGoalId() {
    localStorage.removeItem("editGoalId");
    if (this.editGoalId != "-1") {
      let info: any = { editGoalId: this.editGoalId, dateAt: moment().format("MM/DD/YYYY") };
      info = JSON.stringify(info);
      localStorage.removeItem("editGoalId");
      localStorage.setItem("editGoalId", info);
    }
  }

  redirectTo5Paisa() {
    location.replace(this.loginUrl);
  }

}
