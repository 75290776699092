/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./refute.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./refute.component";
import * as i3 from "@angular/router";
import * as i4 from "../shared/services/common/common.service";
var styles_RefuteComponent = [i0.styles];
var RenderType_RefuteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RefuteComponent, data: {} });
export { RenderType_RefuteComponent as RenderType_RefuteComponent };
export function View_RefuteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "uk-section uk-section-default uk-section-xlarge"], ["uk-height-viewport", "expand: true"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "uk-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "uk-flex uk-flex-column uk-flex-middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["data-src", "./assets/img/warning-blink.gif"], ["height", "50px"], ["uk-img", ""], ["width", "50px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "uk-text-emphasis uk-text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMsg; _ck(_v, 5, 0, currVal_0); }); }
export function View_RefuteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-refute", [], null, null, null, View_RefuteComponent_0, RenderType_RefuteComponent)), i1.ɵdid(1, 114688, null, 0, i2.RefuteComponent, [i3.ActivatedRoute, i4.CommonService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RefuteComponentNgFactory = i1.ɵccf("app-refute", i2.RefuteComponent, View_RefuteComponent_Host_0, {}, {}, []);
export { RefuteComponentNgFactory as RefuteComponentNgFactory };
