/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./redirection.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./redirection.component";
import * as i3 from "@angular/router";
import * as i4 from "../shared/services/common/common.service";
var styles_RedirectionComponent = [i0.styles];
var RenderType_RedirectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RedirectionComponent, data: {} });
export { RenderType_RedirectionComponent as RenderType_RedirectionComponent };
export function View_RedirectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "uk-section uk-section-default uk-section-xlarge"], ["uk-height-viewport", "expand: true"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "uk-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "uk-flex uk-flex-column uk-flex-middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "uk-margin-small-right"], ["uk-spinner", "ratio: 2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "uk-text-emphasis uk-text-center uk-display-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Validating your request"]))], null, null); }
export function View_RedirectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-redirection", [], null, null, null, View_RedirectionComponent_0, RenderType_RedirectionComponent)), i1.ɵdid(1, 114688, null, 0, i2.RedirectionComponent, [i3.ActivatedRoute, i4.CommonService, i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RedirectionComponentNgFactory = i1.ɵccf("app-redirection", i2.RedirectionComponent, View_RedirectionComponent_Host_0, {}, {}, []);
export { RedirectionComponentNgFactory as RedirectionComponentNgFactory };
