import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';

const subject = new Subject();

@Injectable({
  providedIn: 'root'
})
export class BondsService {

  bondsSubject = subject;

  constructor(private http: HttpClient, private router: Router, private activatedRoute: ActivatedRoute) { }


  getBondsSubject(): Observable<any> {
    return this.bondsSubject.asObservable();
  }

  senBondsSubject(action: any, data: any) {
    this.bondsSubject.next({ action, data })
  }

  setBondName(bondName: string) {
    return bondName.toLowerCase().replace(/^\w|\s\w/g, (letter) => {
      return letter.toUpperCase();
    });
  }


  getMarketplace() {
    return this.http.get('bond/marketplace');
  }


  getBondList(reqBody: any) {
    return this.http.post('bond/bondList', reqBody);
  }


  getBondData(instrumentId: string) {
    return this.http.get(`bond/bondData/${instrumentId}`);
  }


  getQuoteData(instrumentId: string, quoteType: string) {
    return this.http.get(`bond/getQuoteData/${instrumentId}/${quoteType}`);
  }


  getUserInfo() {
    return this.http.get('bond/getUserInfo');
  }

  updateDematAccount(reqBody: any) {
    return this.http.post('bond/updateDematAccount', reqBody);
  }


  createHarmoneyUser() {
    return this.http.get('bond/createUser');
  }


  placeOrder(reqBody: any) {
    return this.http.post('bond/placeOrder', reqBody);
  }

  getPendingStatus(instrumentId: any) {
    return this.http.get(`bond/getPendingStatus/${instrumentId}`);
  }

  getUserPortfolio() {
    return this.http.get('bond/getHoldings');
  }

  isPaymentDone(paymentId) {
    return this.http.get(`bond/user-orderStatus/${paymentId}`);
  }

}
