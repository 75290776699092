import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from "../shared/shared.module";

@Component({
  selector: 'app-refute',
  templateUrl: './refute.component.html',
  styleUrls: ['./refute.component.scss']
})
export class RefuteComponent implements OnInit {

  errorMsg = 'Invalid token';
  queryParams: any;
  constructor(private route: ActivatedRoute, private commonService: CommonService) { }

  ngOnInit() {
    this.getQuery();
  }

  getQuery() {
    this.route.queryParams.subscribe((params) => {
      this.errorMsg = params.msg ? params.msg : this.errorMsg;
      this.queryParams = params;
      this.queryParams && this.queryParams.isWeb && this.navigateToDashBoard();
    });
  }

  navigateToDashBoard() {
    location.replace('/dashboard/on-borading-stages');
    this.commonService.showNotification(this.errorMsg, "danger");
  }

}
