import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CommonService } from "../../services/common/common.service";

declare let Razorpay: any;
declare let $: any;

@Component({
  selector: 'app-razor-pay',
  templateUrl: './razor-pay.component.html',
  styleUrls: ['./razor-pay.component.scss']
})
export class RazorPayComponent implements OnInit {

  razorPayUrl = "";
  @Input("userId") userId: string;
  @Input("coupounCode") coupounCode: string;
  @Input("isWeb") isWeb: boolean = false;

  razorpayOrderInfo: any = {};
  disablePay = false;
  errorMsg = "";
  paymentStatus;
  paymentCounter = 0;
  paymentFrequency: string = "1";

  constructor(private commonService: CommonService) {
    this.razorPayUrl = environment.razorPayJS;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.loadRazorPayScript();
  }

  loadRazorPayScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = this.razorPayUrl;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  getOrderId() {
    if (this.paymentFrequency) {
      this.commonService.getOrderId(this.userId, this.coupounCode, this.paymentFrequency).subscribe((res) => {
        if (res && res.statusCode) {
          this.openRazorPay(res.orderInfo);
        } else {
          this.errorMsg = res.msg;
        }
      });
    } else {
      this.openRazorPay(this.razorpayOrderInfo);
    }

  }

  openRazorPay(orderInfo) {
    this.razorpayOrderInfo = orderInfo;
    this.razorpayOrderInfo["modal.backdropclose"] = false;
    this.razorpayOrderInfo["modal.escape"] = false;
    this.razorpayOrderInfo["theme.hide_topbar"] = true;
    this.razorpayOrderInfo["callback_url"] = `${environment.url}pennyDropAccounts/razorPayCallback`;
    this.razorpayOrderInfo["redirect"] = true;
    this.razorpayOrderInfo.theme = { color: "#10551F" }
    this.razorpayOrderInfo.handler = function (response) {
      console.log('Response from razorPay', response);
      // this.disablePay = true;
      this.disablePayNow();
      this.paymentDone(response);
    }.bind(this);
    let razPayObj = new Razorpay(orderInfo);
    razPayObj.open();
  }

  paymentDone(response) {
    setTimeout(() => this.checkForStatus(response.razorpay_payment_id, response.razorpay_order_id, response.razorpay_signature), 5000);
  }

  checkForStatus(paymentId, orderId, checksum) {
    this.paymentStatus = setInterval(() => this.isPaymentDone(paymentId, orderId, checksum), 6000);
  }

  isPaymentDone(paymentId, orderId, checksum) {
    if (this.paymentCounter < 4) {
      this.commonService.checkRazorPayStatus(orderId, paymentId, checksum).subscribe((res) => {
        this.paymentCounter++;
        if (res && res.statusCode) {
          clearInterval(this.paymentStatus);
          if (res.isPaymentDone)
            location.href = `/verified?statusCode=1&msg=&title=Payment Completed&orderId=${orderId}&amount=${res.amount}&isWeb=${this.isWeb}&isPayment=true`;
        } else {
          this.commonService.showNotification(res.msg, "danger");
          this.failedRedirection();
        }
      });
    }
    else {
      this.failedRedirection();
    }
  }

  failedRedirection() {
    clearInterval(this.paymentStatus);
    location.href = `/refute?statusCode=0&msg=This is taking longer than usual. Do not worry, please close the app and check back after 10mins.&title=Payment Processing&shouldRetry=0`;
  }

  disablePayNow() {
    $("#diablePayment").attr("disabled", true);
    $("#showPP").show();
  }
}
