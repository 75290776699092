import { Pipe, PipeTransform } from "@angular/core";
import { CommonService } from "../services/common/common.service";

@Pipe({
    name: "etfSymbol",
})

export class ETFSymbol implements PipeTransform {

    etfs: any = this.commonService.getETFs();
    constructor(private commonService: CommonService) { }

    transform(instrumentId: any): any {
        let symbol: any;
        if (this.etfs && instrumentId) {
            symbol = this.etfs.find(e => e.instrument.id == instrumentId);
            symbol = symbol ? symbol.instrument.symbol : "";
        }
        return symbol;
    }
}
