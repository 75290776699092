import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { Router, ActivatedRoute } from "@angular/router";
import { Subject } from 'rxjs';


const subject = new Subject();

@Injectable({
  providedIn: 'root'
})
export class MfitService {

  mfitSubject = subject;

  constructor(private http: HttpClient, private router: Router, private activatedRoute: ActivatedRoute) { }


  getBondsSubject(): Observable<any> {
    return this.mfitSubject.asObservable();
  }

  senBondsSubject(action: any, data: any) {
    this.mfitSubject.next({ action, data })
  }

  getPresignedPost(reqBody: any) {
    return this.http.post(`mfPortfolios/generate-url`, reqBody);
  }

  uploadToS3(url, formData: FormData) {
    return this.http.put(url, formData);
  }

  extractInfo(reqBody: any) {
    return this.http.post(`mfPortfolios/extract-info`, reqBody);
  }
}
