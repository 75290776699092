import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from "../../services/common/common.service";


@Injectable()
export class NoAuthGuard implements CanActivate {
  constructor(private router: Router, private commonService: CommonService, private activatedRoute: ActivatedRoute) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    let isAuthenticated = this.commonService.isAuthenticated();

    if (isAuthenticated) {
      this.router.navigate(['/dashboard'], { relativeTo: this.activatedRoute });
    }
    return !isAuthenticated;
  }
}
