import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from "@angular/router";
import { Subject } from 'rxjs';
import { CommonService } from "../common/common.service";


const subject = new Subject();

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  dashboardSubject = subject;

  constructor(private http: HttpClient, private router: Router, private activatedRoute: ActivatedRoute, private commonService: CommonService) { }


  getDashboardSubject(): Observable<any> {
    return this.dashboardSubject.asObservable();
  }

  sendDashboardSubject(action: any, data: any) {
    this.dashboardSubject.next({ action, data })
  }

  validatePin(postBody) {
    return this.http.post(`users/validatePin`, postBody);
  }

  logout(postBody) {
    return this.http.post(`users/logout`, postBody);
  }

  getUserDetails() {
    return this.http.get(`users/currentUser`);
  }

  getSavedUserDetails() {
    let userInfo: any = localStorage.getItem('userInfo');
    userInfo = userInfo ? JSON.parse(userInfo) : "";
    return userInfo;
  }

  getUserId() {
    return this.commonService.getUserId();
  }

  getPortfolio() {
    return this.http.get(`users/${this.getUserId()}/portfolio`);
  }

  getGoals() {
    return this.http.get(`users/${this.getUserId()}/goals`);
  }

  getMoneyTransferDay() {
    return this.http.get(`users/${this.getUserId()}/bankDetails/moneyTransferDay`);
  }

  getNextTradingDay() {

  }

  getCompletePortfolio() {
    return this.http.get(`users/getCompletePortfolio`);
  }

  getGoalDetail(goalId: string) {
    return this.http.get(`goals/${goalId}`);
  }

  sendOTP(reqBody: any) {
    return this.http.put(`users/${this.getUserId()}/sendOtpForPhone`, reqBody);
  }

  validateOTP(reqBody: any) {
    return this.http.post(`users/${this.getUserId()}/verifyOtpForPhone`, reqBody);
  }

  updateUserInfo(reqBody: any) {
    reqBody.id = this.getUserId();
    return this.http.put(`users/${reqBody.id}`, reqBody);
  }

  savePANInfo(reqBody: any) {
    return this.http.put(`users/${this.getUserId()}/userKyc`, reqBody);
  }

  putRiskLevel(reqBody: any) {
    return this.http.put(`users/${this.getUserId()}/riskLevel`, reqBody);
  }

  storeRiskProfile(reqBody: any) {
    return this.http.post(`riskProfiles/${this.getUserId()}/storeRiskProfile`, reqBody);
  }


  getMonthlyAmount(reqBody: any) {
    let riskLevel = this.getSavedUserDetails();
    riskLevel = riskLevel && riskLevel.risk.riskLevel ? riskLevel.risk.riskLevel : 5;
    return this.http.post(`risks/${riskLevel}/goalEstimate`, reqBody);
  }


  calculateTTG(reqBody: any) {
    let riskLevel = this.getSavedUserDetails();
    reqBody.riskLevel = riskLevel && riskLevel.risk.riskLevel ? riskLevel.risk.riskLevel : 5;
    return this.http.post(`goals/calculateTTG`, reqBody);
  }

  getAllBrokerList(){
    return this.http.get(`brokers/getAllBrokers`);
  }
}
