import { Component, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators, FormGroup } from '@angular/forms';
import { CommonService } from "../shared/services/common/common.service";
import { RazorPayComponent } from "../shared/component/razor-pay/razor-pay.component";
import { ActivatedRoute } from '@angular/router';
import * as JsEncryptModule from 'jsencrypt';
import { environment } from '../../environments/environment';


declare let UIkit;
declare let $;
@Component({
  selector: 'app-get-account-info',
  templateUrl: './get-account-info.component.html',
  styleUrls: ['./get-account-info.component.scss']
})
export class GetAccountInfoComponent implements OnInit {

  bankForm: any;
  userId: string;
  accessToken: string;
  coupounCode: string;
  isWeb: boolean = false;
  dropPenny: boolean = false;
  paymentBlock: boolean = false;
  pennyOrderId: string;
  pennyErrorMsg: string;
  pennyBankObj = { isPennyDrop: false, isBankVerified: false, isRejected: false, };
  verifyBankObj;
  apiCall = false;
  userAccountInfo = { accountNo: "", ifscCode: "", payType: "UPI", tpvBankId: "", phone: "", userAccountC: { accountVerifiedCount: 0 } };
  tpvBankError: boolean = false;
  tvpBankList = [];
  selectedItems = [];
  dropdownSettings: any = {};
  isSinglePaymentValid = false;
  constructor(private fb: FormBuilder, private commonService: CommonService, private router: ActivatedRoute) { }


  ngOnInit() {
    this.getQuery();
    this.setToken();
    this.getTVPBanks();
    this.initForm();
    this.getUserAccount();
    this.setDropdownSetting(false);
  }

  getQuery() {
    this.router.params.subscribe((params) => {
      this.userId = params.userId ? params.userId : this.userId;
      this.accessToken = params.accessToken ? params.accessToken : this.accessToken;
      this.coupounCode = params.coupounCode ? params.coupounCode : this.coupounCode;

      //Check for whether its from web
      if (this.accessToken == "-1") {
        this.accessToken = this.commonService.getToken();
        this.isWeb = true;
      }
    });
  }

  getUserAccount() {
    this.commonService.getAccount(this.userId).subscribe((res) => {
      if (res && res.statusCode) {
        this.userAccountInfo = res.accountInfo;
        //Allow payment if user has tried for 2 times and both are invalid
        this.paymentBlock = this.userAccountInfo.userAccountC.accountVerifiedCount > 1 && !this.userAccountInfo.accountNo ? true : false;
        this.setDisable();
      }
    });
  }

  setToken() {
    let tokenObj = {
      id: this.accessToken,
      userId: this.userId,
    }
    this.commonService.setToken(tokenObj);
  }

  initForm() {
    this.bankForm = this.fb.group({
      accountNo: [this.userAccountInfo.accountNo, [Validators.required, Validators.pattern(/^\d{9,18}$/)]],
      ifscCode: [this.userAccountInfo.ifscCode, [Validators.required, Validators.pattern(/^[A-Za-z]{4}[a-zA-Z0-9]{7}$/)]],
      tpvBankId: [this.userAccountInfo.tpvBankId],
      payType: [this.userAccountInfo.payType, [Validators.required]],
    });
  }

  setDisable() {
    this.bankForm.patchValue({
      accountNo: this.userAccountInfo.accountNo,
      ifscCode: this.userAccountInfo.ifscCode,
      payType: this.userAccountInfo.payType,
      tpvBankId: this.userAccountInfo.tpvBankId,
    });

    this.userAccountInfo.accountNo && this.bankForm.get('accountNo').disable();
    this.userAccountInfo.ifscCode && this.bankForm.get('ifscCode').disable();

    if (this.userAccountInfo.tpvBankId) {
      this.selectedItems = this.tvpBankList.filter((b) => b.id == this.userAccountInfo.tpvBankId);
      this.bankForm.get('tpvBankId').disable();
      this.setDropdownSetting(true);
    }


    // if (this.isSinglePaymentValid) {
    //   // this.bankForm.get("payType").setValue("NETBANKING")
    //   this.bankForm.get("payType").setValue("UPI")
    // }
  }

  setDropdownSetting(disabled) {
    this.dropdownSettings = {
      singleSelection: true,
      text: "Select Bank",
      enableSearchFilter: true,
      enableCheckAll: false,
      searchAutofocus: true,
      noDataLabel: "Bank not present select other",
      selectAllText: "",
      unSelectAllText: "",
      disabled,
      maxHeight: "150"
    };
  }

  getTVPBanks() {
    this.commonService.getTVPBank().subscribe((res) => {
      if (res && res.statusCode) {
        let tvpBanks = res.tvpBanks;
        let tempBanks = [];
        tvpBanks.forEach((t) => {
          tempBanks.push({ id: t.id, itemName: t.bankName });
        });
        //Make Other as last options
        let otherBank = tempBanks.shift();
        tempBanks.push(otherBank);
        this.tvpBankList = tempBanks;
      }
    })
  }

  get f() { return this.bankForm.controls; }

  pennyDrop() {
    if (this.bankForm.valid) {
      if (this.bankForm.get("accountNo").disabled && this.bankForm.get("ifscCode").disabled) this.callPenny();
      else this.toggleConfirmAccountNo(true);
    }
  }

  toggleConfirmAccountNo(show: boolean) {
    show && UIkit.modal('#confirmAccount', { 'bg-close': false }).show();
    !show && UIkit.modal('#confirmAccount', { 'bg-close': false }).hide();
  }

  callPenny() {
    if (this.bankForm.valid) {
      this.toggleConfirmAccountNo(false);
      let reqBody = JSON.stringify(this.bankForm.getRawValue());
      reqBody = this.encrypt(reqBody);
      this.dropPenny = true;
      this.apiCall = true;
      this.commonService.pennyDrop({ reqBody }).subscribe((res) => {
        this.apiCall = false;
        if (res && res.statusCode) {
          this.pennyOrderId = res.pennyOrderId;
          this.pennyBankObj.isPennyDrop = this.pennyOrderId ? true : false;
          //If penny drop is successfull then start to verify from service
          this.pennyBankObj.isPennyDrop && this.processPennyVerified();
        }
        //Check for error message
        this.pennyErrorMsg = res && !res.statusCode && res.msg ? res.msg : '';
        //Whther its rejected
        this.pennyBankObj.isRejected = this.pennyErrorMsg && res.isRejected ? true : false;
        //If rejected make penny to account as suucess;
        this.pennyBankObj.isPennyDrop = this.pennyBankObj.isRejected ? true : this.pennyBankObj.isPennyDrop;
      });
    }
  }

  encrypt(reqBody) {
    let bufferValue1 = "";
    let encrypt = new JsEncryptModule.JSEncrypt();
    encrypt.setPublicKey(`-----BEGIN PUBLIC KEY-----
    MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAIlbArvv/i+qJ+vjk1LMGDrR/v+Qa9sN
    OJMWDt2txAS3TkVUHOtbpSCFEEfS/yj2sBvHLIPnrmSGgAZWmRTCEtcCAwEAAQ==
    -----END PUBLIC KEY-----`);
    let splitData1 = reqBody.match(/.{1,30}/g);
    splitData1.forEach((s) => {
      bufferValue1 += encrypt.encrypt(s);
    });
    return bufferValue1;
  }


  processPennyVerified() {
    this.verifyBankObj = setInterval(() => this.isPennyDrop(), 5000);
    // this.verifyBankObj = setInterval(() => this.isPennyDrop(), 10000);

    // this.verifyBankObj = setInterval(() => this.isPennyDrop(), 120000);
  }

  isPennyDrop() {
    this.commonService.isPennyDrop(this.pennyOrderId).subscribe((data: any) => {
      if (data && data.statusCode) {
        this.pennyBankObj.isBankVerified = data.statusCode && data.validBank ? true : false;
        // location.href = `/verified?accessToken=${data.validationCode}`;
      } else {
        this.pennyErrorMsg = data && !data.statusCode && data.msg ? data.msg : '';
        this.pennyBankObj.isRejected = this.pennyErrorMsg && data.isRejected ? true : false;
        this.clearTimer();
      }
      !this.pennyErrorMsg && this.pennyBankObj.isBankVerified && this.clearTimer();
    })
  }

  clearTimer() {
    clearInterval(this.verifyBankObj);
  }

  editAccNo() {
    this.dropPenny = false;
    this.pennyBankObj.isBankVerified = false;
    this.pennyBankObj.isPennyDrop = false;
    this.pennyBankObj.isRejected = false;
    this.pennyErrorMsg = "";
    this.selectedItems = [];
    this.bankForm.reset();
    this.getUserAccount();
  }

  redirectToPayment() {
    //@ts-ignore
    window.location = `${environment.url}/processingPayments/makePayment/${this.userId}/${this.coupounCode}`;
  }

  onBankSelect(bank) {
    if (bank.id != "1") {
      this.bankForm.get('tpvBankId').setValue(bank.id);
      // console.log(this.bankForm.getRawValue());
    } else {
      this.editBank();
      this.bankForm.get('tpvBankId').setValue("");
      this.selectedItems = [];
    }
  }

  OnBankDeSelect(bank) {
    this.bankForm.get('tpvBankId').setValue("");
  }

  editBank() {
    this.tpvBankError = !this.tpvBankError;
  }

  cancelPayment() {
    location.href = "/refute?statusCode=0&msg=Payment cancelled&title=Payment Incomplete&shouldRetry=1";
  }

  openDropDown() {
    $('.tagToBody').css('top', '').css('left', '');
  }
}
