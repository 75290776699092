import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from '../../environments/environment';
import { CommonService } from './services/common/common.service';


@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    baseUrl: String = '';
    userProfileUrl: String = '';

    constructor(private commonService: CommonService) {
        this.baseUrl = environment.url;
    }
    //function which will be called for all http calls
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let headers = request.headers;
        let aesToken = this.commonService.getToken();

        if (aesToken) {
            if (request.body && request.body.constructor == FormData) {
                headers.delete("Content-Type");
            } else {
                headers = headers
                    .set("Authorization", `${aesToken}`)
                    .set("Content-Type", "application/json");
            }
        }

        let updatedRequest;
        //how to update the request Parameters
        if (request.url.search('ipify') != -1 || request.url.search('mf-data') != -1) {
            updatedRequest = request.clone({ url: request.url })
        } else {
            updatedRequest = request.clone({ url: this.baseUrl + request.url, headers: headers });
        }

        //logging the updated Parameters to browser's console
        return next.handle(updatedRequest).pipe(
            tap(
                event => {
                    //logging the http response to browser's console in case of a success
                    if (event instanceof HttpResponse) {
                        // console.log('Setting ttl')
                        this.commonService.setTTL();
                    }
                },
                error => {
                    //logging the http response to browser's console in case of a failuer
                    if (error instanceof HttpErrorResponse) {
                        // console.log("api call error :", error);

                        if (error.status === 401) {
                            // redirect to the login route
                            // this.userFlowService.logout();
                            // // this.commonService.showNotification('Session Expired!!', 'danger');
                            // // this.commonService.showNotification('Login Again!', 'danger');
                            // this.commonService.removeToken();
                            return;
                        }

                        if ([404].includes(error.status)) {
                            this.commonService.showNotification('Url Not Found!', 'danger');
                            return;
                        }


                        if ([0, 500].includes(error.status)) {
                            let msg = error.error && error.error.message ? error.error.message : 'Something went wrong!!'
                            // this.commonService.showNotification(msg, 'danger');
                            return;
                        }

                        if (error.error.message != "") {
                            //document.getElementById("#error").innerHTML = event.error.message;
                        }

                    }
                }
            )
        );
    }
}