<div class="uk-section uk-section-default uk-section-medium">
    <div class="uk-container">
        <p class="uk-heading-divider uk-text-center">Pricing plan</p>
        <h2 class="uk-text-emphasis uk-text-bolder uk-text-center uk-margin-small">ADVISORY FEES</h2>

        <div class="uk-margin-medium uk-grid-match uk-child-width-1-2@m uk-grid uk-animation-slide-left-small" uk-grid="">
            <div class="uk-first-column">
                <p class="uk-article-meta uk-margin-small">Product Plan</p>
                <ul class="uk-list uk-list-bullet uk-margin-small">
                    <li>You get customised advice into a diversified basket of Exchange Traded Funds (ETFs) month-on-month, for each goal</li>
                    <li>This plan is valid for 12 months</li>
                </ul>
                <p class="uk-article-meta uk-margin-small">Features</p>
                <ul class="uk-list uk-list-bullet uk-margin-small">
                    <li>Facility to create up to 10 different goals, be it for your dream car or the perfect vacation
                    </li>
                    <li>Customised advice for each goal into Exchange Traded Funds (ETFs)</li>
                </ul>
                <div>
                    <a target="_blank" href="https://tavaga.com/about.html" class="">Nitin Mathur</a>
                    <span class="uk-text-meta uk-display-block">CEO</span>
                </div>
            </div>
            <div>
                <div class="uk-card uk-card-default uk-height-large uk-card-body fees-color uk-animation-slide-right-small">
                    <h3 class="uk-heading-small white-color uk-text-bold uk-text-center">Rs {{ activeFrequency?.amount }}</h3>
                    <ul>
                        <li class="uk-text-normal">Valid for {{ activeFrequency?.noOfDays }} months</li>
                        <li class="uk-text-normal">Create up to 10 different goals</li>
                        <li class="uk-text-normal">Facility to invest anything you want with our One-Time Boost feature</li>
                        <li class="uk-text-normal">Customised advice for each goal</li>
                        <li class="uk-text-normal">Invest into commission-free ETFs</li>
                        <li class="uk-text-normal">Bank-grade security to keep your investments safe</li>
                        <li class="uk-text-normal">SEBI- registered Investment Advisor, giving customised advice for all your goals</li>
                    </ul>
                    <div class="uk-position-bottom-center uk-padding-medium">
                        <span (click)="clickPromocode()" class="uk-button uk-button-link" uk-toggle="target: #promo-code; animation:  uk-animation-slide-left, uk-animation-slide-bottom">Apply
                            Promo code</span>
                        <div *ngIf="isUserLoggedIn" id="promo-code" hidden>
                            <input style="text-transform:uppercase" [ngClass]="{'uk-form-danger': isCoupounErr }" maxlength="6" #coupounCode (keyup)="checkCoupounCode(coupounCode.value)" class="uk-input uk-form-width-medium uk-margin-small-top" type="text" placeholder="Promo Code">
                            <span *ngIf="isCoupounErr" class="uk-text-small uk-text-danger uk-text-center uk-animation-slide-bottom uk-display-block">{{ isCoupounErr }}</span>
                        </div>
                        <div *ngIf="!isUserLoggedIn" id="promo-code" hidden></div>
                        <div class="uk-text-center uk-margin-small">
                            <button (click)="navigateToPayment()" [disabled]="isCoupounErr" class="uk-button round-coner uk-button-secondary">Pay
                                Now</button>
                        </div>
                    </div>



                </div>
            </div>
        </div>

    </div>
</div>