import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import * as moment from "moment";
import { environment } from '../../environments/environment';

//Interceptor
import { RequestInterceptor } from './request.interceptor';
const httpInterceptor = { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true };



//Auth Guard
import { AuthGuard } from './guards/auth/auth.guard';
import { NoAuthGuard } from './guards/auth/no-auth.guard';
const guards = [AuthGuard, NoAuthGuard];


//Component
import { MainLayoutComponent } from './component/main-layout/main-layout.component';
import { LoginComponent } from '../login/login.component';
import { LoaderComponent } from './component/loader/loader.component';
import { RazorPayComponent } from './component/razor-pay/razor-pay.component';
import { DatePickerComponent } from './component/date-picker/date-picker.component';
import { AskUserInfoComponent } from "./component/ask-user-info/ask-user-info.component";
import { SmallDotsComponent } from './component/small-dots/small-dots.component';

const component = [MainLayoutComponent, LoginComponent, LoaderComponent, RazorPayComponent, DatePickerComponent, AskUserInfoComponent, SmallDotsComponent];


//Services
// import { UserFlowService } from './services/user-flow/user-flow.service';
import { CommonService } from './services/common/common.service';
import { DashboardService } from './services/dashboard/dashboard.service';
import { IciciService } from './services/icici/icici.service';
import { BrokersService } from './services/brokers/brokers.service';
import { BondsService } from './services/bonds/bonds.service';
import { MfitService } from './services/mfit/mfit.service';


const services = [CommonService, DashboardService, IciciService, BrokersService, BondsService, MfitService];


//Directives
import { LabelsDirective } from './directives/labels/labels.directive';

const directives = [LabelsDirective]

//Pipes
import { FixedDecimal } from "./pipe/fixedDecimal";
import { ETFSymbol } from "./pipe/etfSymbol";
import { MoneyAbv } from "./pipe/moneyAbv";
import { NumberSeperator } from "./pipe/numberSeperator";
import { DateFormater } from "./pipe/dateFormater";

const pipe = [FixedDecimal, ETFSymbol, MoneyAbv, NumberSeperator, DateFormater];

import { MustMatch } from "./helper/must-match.validator";



const plugins = [AngularMultiSelectModule];
@NgModule({
  declarations: [...component, ...pipe, ...directives],
  imports: [
    RouterModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMultiSelectModule,
  ],
  providers: [...services, httpInterceptor, ...guards, ...pipe],
  exports: [...pipe, ...plugins, ...directives, RazorPayComponent, DatePickerComponent, SmallDotsComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class SharedModule { }
export { MainLayoutComponent, LoaderComponent };
export { CommonService, DashboardService, IciciService, BrokersService, BondsService, MfitService };
export { AuthGuard, NoAuthGuard };
export { RazorPayComponent, DatePickerComponent, AskUserInfoComponent, SmallDotsComponent };
export { MustMatch };
export { LabelsDirective };
export { moment, environment };