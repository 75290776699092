import { Component, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators, FormGroup } from '@angular/forms';
import { CommonService } from "../../services/common/common.service";


declare let UIkit: any;
declare let $: any;


@Component({
  selector: 'app-ask-user-info',
  templateUrl: './ask-user-info.component.html',
  styleUrls: ['./ask-user-info.component.scss']
})
export class AskUserInfoComponent implements OnInit {


  userDetailsForm: FormGroup;
  errMsg: string = "";
  apiCall: boolean = false;
  userRiskGame: any;
  constructor(private fb: FormBuilder, private commonService: CommonService) { }


  ngOnInit() {
    this.initForm();
  }

  ngAfterViewInit() {
    this.subscribedData();
  }

  initForm() {
    this.userDetailsForm = this.fb.group({
      fullName: ["", [Validators.required, Validators.pattern(/^[a-zA-Z ]{2,35}$/)]],
      email: ["", [Validators.required, Validators.email]],
      mobileNo: ["", [Validators.required, Validators.pattern(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/), Validators.maxLength(10)]],
    });

  }

  get f() { return this.userDetailsForm.controls; }


  subscribedData() {
    this.commonService.getCommonSubject()
      .subscribe(res => {
        switch (res.action) {
          case 'showASKUserInfo': {
            let { storeRiskProfile, riskGameSteps } = res.data;
            storeRiskProfile = storeRiskProfile && storeRiskProfile.map((s) => s.answer);
            this.userRiskGame = { storeRiskProfile, riskGameSteps };
            this.showUserModel();
            break;
          }
          case 'closeASKUserInfo': {
            this.hideUserModel();
            break;
          }
        }
      });
  }

  showUserModel() {
    UIkit.modal('#getUserInfo').show();
  }

  hideUserModel() {
    UIkit.modal('#getUserInfo').hide();
  }

  subscribeUser() {
    if (this.userDetailsForm.valid) {
      let getUserInfo = this.userDetailsForm.getRawValue();
      getUserInfo.userAssest = this.addAssetsUsers();
      this.commonService.subscribeUser(getUserInfo).subscribe((res) => {
        this.commonService.sendCommonSubject('closeASKUserInfo', { getUserInfo });
        this.userDetailsForm.reset();
        // this.commonService.setLocalStorage('givenInfo', JSON.stringify(getUserInfo));
      });
    }
  }


  addAssetsUsers() {
    let userAssest = this.commonService.getDataFromLocalStorage("userAssest");
    if (userAssest) {
      let tempAssest = { [userAssest]: {} };
      for (let [key, value] of Object.entries(this.userRiskGame)) {
        tempAssest[userAssest][`${key}`] = value;
      }
      return tempAssest;
    }
    return "";
  }

}
