import { Pipe, PipeTransform } from "@angular/core";
import { CommonService } from "../services/common/common.service";

@Pipe({
    name: "numberSeperator",
})

export class NumberSeperator implements PipeTransform {

    constructor() { }

    transform(amount: string): any {
        return amount ? Number.parseInt(amount).toLocaleString('en-IN') : "";
    }
}
