import { Component, OnInit } from '@angular/core';
import { CommonService, BrokersService } from "../shared/shared.module";
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-alice-blue-reponse',
  templateUrl: './alice-blue-reponse.component.html',
  styleUrls: ['./alice-blue-reponse.component.scss']
})
export class AliceBlueReponseComponent implements OnInit {

  authCode: string;
  brokerId: string
  isWeb: any;
  constructor(private commonService: CommonService, private activatedRoute: ActivatedRoute, private router: Router, private brokersService: BrokersService) { }


  ngOnInit() {
    this.getUserDetails();
  }


  getQuery() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.authCode = params.authCode;
      this.brokerId = params.userId;
      this.authCode ? this.aliceBlueAccessToken() : this.failedRequest("Invalid Token");
    });
  }

  getUserDetails() {
    this.commonService.getUserInfo().subscribe((res) => {
      this.commonService.setUserInfo(res);
      this.getQuery();
      this.checkForWeb();
    });
  }

  checkForWeb() {
    this.isWeb = this.commonService.isWebRequest();
  }


  processForTrades(editGoalId: string) {
    // location.href = `/icici-trades/${this.iciciUserId}/${editGoalId}`;
    this.router.navigate([`dashboard/execute-trades/${editGoalId}`]);
  }

  aliceBlueAccessToken() {
    let reqBody = {
      authCode: this.authCode,
      brokerId: this.brokerId,  
      brokerName: "ALICEBLUE",
    }
    this.brokersService.linkAliceBlue(reqBody).subscribe((res: any) => {
      if (res && res.statusCode) {
        this.isBrokerLink();
      } else {
        let msg = res.message ? res.message : "Something went Wrong!";
        this.failedRequest(msg);
      }
    }, (err) => {
      console.log(err);
      let msg = "Something went wrong!";
      this.failedRequest(msg);
    });
  }

  failedRequest(msg: string) {
    location.href = this.isWeb ? `/refute?statusCode=0&msg=${msg}&isWeb=${this.isWeb}` : `/refute?statusCode=0&msg=${msg}`;
  }



  isBrokerLink() {
    let editGoalId: any = localStorage.getItem("editGoalId");
    if (editGoalId) {
      editGoalId = JSON.parse(editGoalId);
      let noOfDays = this.commonService.getDateDifference(editGoalId.dateAt, new Date(), "days");
      if (noOfDays == 0 && editGoalId.editGoalId) this.processForTrades(editGoalId.editGoalId);
      else location.href = this.isWeb ? `/verified?isWeb=${this.isWeb}` : `/verified`;
    } else {
      location.href = this.isWeb ? `/verified?isWeb=${this.isWeb}` : `/verified?msg=Broker%20linked`;
    }
  }

}
