import { Component, OnInit } from '@angular/core';
import { LoginComponent } from '../../../login/login.component';
import { CommonService } from "../../services/common/common.service";
import { Router } from "@angular/router";
import { AskUserInfoComponent } from "../ask-user-info/ask-user-info.component";

declare let UIkit: any;
declare let $: any;


@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  isLoggedIn: boolean;
  showHeader: boolean = true;
  constructor(private commonService: CommonService, private router: Router) { }

  ngOnInit() {
    this.subscribedData();
    this.isAuthPresent();
  }

  subscribedData() {
    this.commonService.getCommonSubject()
      .subscribe(res => {
        switch (res.action) {
          case 'hideHeader': {
            this.showHeader = false;
            break;
          }
        }
      })
  }

  isAuthPresent() {
    let aesToken = this.commonService.getToken();
    this.isLoggedIn = aesToken ? true : false;
  }

  showDashboard() {
    if (this.isLoggedIn) {
      this.router.navigate(['/dashboard']);
    } else {
      UIkit.modal('#login-form').show();
    }
  }

}
