/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./verified.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./verified.component";
import * as i3 from "@angular/router";
import * as i4 from "../shared/services/common/common.service";
import * as i5 from "../shared/services/dashboard/dashboard.service";
var styles_VerifiedComponent = [i0.styles];
var RenderType_VerifiedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VerifiedComponent, data: {} });
export { RenderType_VerifiedComponent as RenderType_VerifiedComponent };
export function View_VerifiedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "uk-section uk-section-default uk-section-xlarge"], ["uk-height-viewport", "expand: true"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "uk-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "uk-flex uk-flex-column uk-flex-middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["data-src", "./assets/img/check.gif"], ["height", "50px"], ["uk-img", ""], ["width", "50px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "uk-text-emphasis uk-text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.successMsg; _ck(_v, 5, 0, currVal_0); }); }
export function View_VerifiedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-verified", [], null, null, null, View_VerifiedComponent_0, RenderType_VerifiedComponent)), i1.ɵdid(1, 114688, null, 0, i2.VerifiedComponent, [i3.ActivatedRoute, i4.CommonService, i3.Router, i5.DashboardService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VerifiedComponentNgFactory = i1.ɵccf("app-verified", i2.VerifiedComponent, View_VerifiedComponent_Host_0, {}, {}, []);
export { VerifiedComponentNgFactory as VerifiedComponentNgFactory };
