import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../common/common.service";
export class BrokersService {
    constructor(http, commonService) {
        this.http = http;
        this.commonService = commonService;
    }
    getTradesDetails(reqBody) {
        reqBody.userId = this.commonService.getUserId();
        return this.http.post(`users/basketOrderWithEditGoalId`, reqBody);
    }
    //Alice blue API
    linkAliceBlue(token) {
        return this.http.post(`alice-blues/alice-blue-link`, token);
    }
    executeAliceBlueTrades(reqBody) {
        return this.http.post(`alice-blues/alice-blue-order`, reqBody);
    }
    //End Alice blue API
    linkZerodha(reqBody) {
        return this.http.post(`broker-links/zerodha`, reqBody);
    }
    updateBrokerAccessToken(reqBody) {
        let userId = this.commonService.getUserId();
        return this.http.put(`users/${userId}/updatebrokerinfo`, reqBody);
    }
    //5paisa blue API
    link5Paisa(token) {
        return this.http.post(`fivePaisas/5paisa-link`, token);
    }
    execute5PaisaTrades(reqBody) {
        return this.http.post(`fivePaisas/5paisa-order`, reqBody);
    }
    //End 5paisa API
    linkKotak(reqBody) {
        return this.http.post(`kotaks/link-broker`, reqBody);
    }
    executeKotakTrades(reqBody) {
        return this.http.post(`kotaks/place-order`, reqBody);
    }
    getEdelweissToken() {
        return this.http.get(`edels/brokerToken`);
    }
    linkEdelweiss(body) {
        return this.http.post(`edels/link-broker`, body);
    }
    executeEdelweissTrades(reqBody) {
        return this.http.post(`edels/place-order`, reqBody);
    }
}
BrokersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BrokersService_Factory() { return new BrokersService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CommonService)); }, token: BrokersService, providedIn: "root" });
