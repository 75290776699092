import { Directive, ElementRef, Input } from '@angular/core';
import { CommonService } from "../../services/common/common.service";


@Directive({
  selector: '[appLabels]'
})
export class LabelsDirective {


  @Input('labelType') labelType: any;

  constructor(private elementRef: ElementRef, private commonService: CommonService) { }
  // label-danger
  // label-warning
  // label-warm

  ngOnInit() {
    this.elementRef.nativeElement.classList.add(this.labelType);

  }

}
