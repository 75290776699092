// import { Injectable } from '@angular/core';
// import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
// import { Observable } from 'rxjs';
// import { CommonService } from "../../services/common/common.service";

// @Injectable()
// export class AuthGuard implements CanActivate {

//   constructor(private router: Router, private commonService: CommonService, private activatedRoute: ActivatedRoute) { }

//   canActivate(
//     next: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
//     let userDetails: any = this.commonService.getDataFromLocalStorage("userInfo");
//     userDetails = userDetails ? JSON.parse(userDetails) : "";
//     if (userDetails) {
//       let { userKycId, phone, brokerId, riskId } = userDetails;
//       //Contructing valid url
//       let splitSlash: any = new URL(`https://localhost:4200${state.url}`);
//       splitSlash = splitSlash.pathname.split("/");

//       // Pending user info
//       if (!phone || !userKycId) {
//         if (splitSlash.includes("on-borading-stages")) return true;
//         else this.router.navigate(['/dashboard/on-borading-stages'], { queryParams: { returnUrl: state.url } });
//       }
//       //pending risk simulation
//       else if (!riskId) this.router.navigate(['/risk-profile'], { queryParams: { returnUrl: state.url } });

//       //rest stages like payment and broker set
//       else if (!brokerId) {
//         if (splitSlash.includes("pending-phase")) return true;
//         else this.router.navigate(['/dashboard/pending-phase'], { queryParams: { returnUrl: state.url } });
//       }
//       else if (next.queryParams && next.queryParams.returnUrl) {
//         this.router.navigateByUrl(next.queryParams.returnUrl);
//       }
//       else if (brokerId) {
//         if (splitSlash.includes("dashboard")) return true;
//         else this.router.navigate(['/dashboard'], { queryParams: { returnUrl: state.url } });
//       }
//       else return true;
//     } else {
//       // not logged in so redirect to login page with the return url
//       // queryParams: { returnUrl: state.url }
//       this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
//     }
//   }
// }


import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from "../../services/common/common.service";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private commonService: CommonService, private activatedRoute: ActivatedRoute) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.commonService.getToken()) {
      return true;
    }
    // not logged in so redirect to login page with the return url
    // queryParams: { returnUrl: state.url }
    this.router.navigate(['/'], { relativeTo: this.activatedRoute });
  }
}
