/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./icici-response.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./icici-response.component";
import * as i3 from "../shared/services/common/common.service";
import * as i4 from "@angular/router";
import * as i5 from "../shared/services/icici/icici.service";
var styles_IciciResponseComponent = [i0.styles];
var RenderType_IciciResponseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IciciResponseComponent, data: {} });
export { RenderType_IciciResponseComponent as RenderType_IciciResponseComponent };
export function View_IciciResponseComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "uk-container uk-container-large"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "uk-text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["uk-spinner", "ratio: 3"]], null, null, null, null, null))], null, null); }
export function View_IciciResponseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-icici-response", [], null, null, null, View_IciciResponseComponent_0, RenderType_IciciResponseComponent)), i1.ɵdid(1, 114688, null, 0, i2.IciciResponseComponent, [i3.CommonService, i4.ActivatedRoute, i4.Router, i5.IciciService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IciciResponseComponentNgFactory = i1.ɵccf("app-icici-response", i2.IciciResponseComponent, View_IciciResponseComponent_Host_0, {}, {}, []);
export { IciciResponseComponentNgFactory as IciciResponseComponentNgFactory };
