<div class="uk-section uk-section-default uk-height-viewport uk-background-muted">
    <div class="uk-container uk-padding">
        <div *ngIf="!isMailSend">
            <div class="uk-text-center m90">
                <!-- <div uk-spinner="ratio: 3"></div> -->
                <app-small-dots></app-small-dots>
                <p>Sending mail to <span class="uk-text-italic">{{queryParams.email}}</span></p>

            </div>
        </div>
        <div *ngIf="isMailSend && !emailEdit">
            <!-- Mail verification stage -->
            <div class="uk-flex-center uk-animation-slide-bottom-medium">
                <div class="uk-card-small uk-card-default uk-text-center">
                    <div class="uk-card-body pb">
                        <img data-src="./assets/svg/mail-sent-animate.svg" width="300px" alt="" uk-img>
                        <h4 class="uk-card-title uk-text-center uk-text-bold uk-margin-remove">Hi,</h4>
                        <p>Please verify that your email address is <span
                                class="uk-text-italic">{{queryParams.email}}.</span></p>

                    </div>
                    <div class="uk-text-center" *ngIf="mailerAPICalled >= emailLimit">
                        <span class="uk-text-danger">Kindly resend email again to verify time expires!!</span>
                    </div>
                    <div class="uk-padding-small uk-margin uk-padding-remove-bottom">
                        <p>Check your mail box</p>
                    </div>
                    <div class="uk-padding-small uk-padding-remove-top">
                        <a (click)="editEmail()" href="javascript:void(0)">RESEND EMAIL</a>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="emailEdit" class="uk-animation-slide-bottom-medium">
            <form [formGroup]="emailForm" (ngSubmit)="resendEmail()">
                <div class="uk-text-center m90">
                    <div class="uk-inline uk-margin-bottom">
                        <span class="uk-form-icon" uk-icon="icon: user"></span>
                        <input formControlName="email" placeholder="Email" class="uk-input"
                            [ngClass]="{'uk-form-danger':f.email.errors}" type="text">
                    </div>
                    <div>
                        <button [disabled]="emailForm.invalid" type="submit"
                            class="uk-button uk-text-secondary uk-width-small open-mail">SEND MAIL</button>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>