import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from "../common/common.service";


@Injectable({
  providedIn: 'root'
})
export class BrokersService {

  constructor(private http: HttpClient, private commonService: CommonService) { }

  getTradesDetails(reqBody) {
    reqBody.userId = this.commonService.getUserId();
    return this.http.post(`users/basketOrderWithEditGoalId`, reqBody);
  }

  //Alice blue API

  linkAliceBlue(token: any) {
    return this.http.post(`alice-blues/alice-blue-link`, token);
  }

  executeAliceBlueTrades(reqBody: any) {
    return this.http.post(`alice-blues/alice-blue-order`, reqBody);
  }
  //End Alice blue API


  linkZerodha(reqBody: any) {
    return this.http.post(`broker-links/zerodha`, reqBody);
  }

  updateBrokerAccessToken(reqBody) {
    let userId = this.commonService.getUserId();
    return this.http.put(`users/${userId}/updatebrokerinfo`, reqBody);
  }


  //5paisa blue API
  link5Paisa(token: any) {
    return this.http.post(`fivePaisas/5paisa-link`, token);
  }

  execute5PaisaTrades(reqBody: any) {
    return this.http.post(`fivePaisas/5paisa-order`, reqBody);
  }
  //End 5paisa API

  linkKotak(reqBody: any) {
    return this.http.post(`kotaks/link-broker`, reqBody);
  }

  executeKotakTrades(reqBody: any) {
    return this.http.post(`kotaks/place-order`, reqBody);
  }

  getEdelweissToken() {
    return this.http.get(`edels/brokerToken`);
  }

  linkEdelweiss(body: any) {
    return this.http.post(`edels/link-broker`, body);
  }

  executeEdelweissTrades(reqBody: any) {
    return this.http.post(`edels/place-order`, reqBody);
  }


}
