import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
const subject = new Subject();
export class MfitService {
    constructor(http, router, activatedRoute) {
        this.http = http;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.mfitSubject = subject;
    }
    getBondsSubject() {
        return this.mfitSubject.asObservable();
    }
    senBondsSubject(action, data) {
        this.mfitSubject.next({ action, data });
    }
    getPresignedPost(reqBody) {
        return this.http.post(`mfPortfolios/generate-url`, reqBody);
    }
    uploadToS3(url, formData) {
        return this.http.put(url, formData);
    }
    extractInfo(reqBody) {
        return this.http.post(`mfPortfolios/extract-info`, reqBody);
    }
}
MfitService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MfitService_Factory() { return new MfitService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i2.ActivatedRoute)); }, token: MfitService, providedIn: "root" });
