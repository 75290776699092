<div class="uk-animation-slide-bottom-small">
    <span class="uk-text-meta uk-text-danger uk-padding-small-bottom uk-display-block">{{ errorMsg }}</span>
    <div class="uk-text-left">
        <label for="accountNo">Pay For</label>
        <div>
            <input class="uk-radio" id="2" type="radio" value="2" [(ngModel)]="paymentFrequency"
                name="paymentFrequency">
            <span for="2" class="uk-margin-small-left uk-text-small">HALF YEARLY
                <span class="uk-text-meta uk-text-small">(180 days)</span>
            </span>
        </div>
        <div>
            <input class="uk-radio" type="radio" id="1" [(ngModel)]="paymentFrequency" value="1" name="paymentFrequency"
                checked>
            <span id="1" class="uk-margin-small-left uk-text-small">YEARLY
                <span class="uk-text-meta uk-text-small">(365 days)</span>
            </span>
        </div>
    </div>

    <button [disabled]="disablePay" id="diablePayment" (click)="getOrderId()"
        class="uk-button uk-width-1-2@l uk-text-center uk-button-primary uk-margin-top tavaga-btn">
        Pay Now
    </button>

    <div style="display: none;" id="showPP" class="uk-padding-small-top">
        <p class="uk-text-primary uk-text-small uk-margin-remove">Please wait, while we validate your payment!</p>
        <div class="uk-text-center uk-icon uk-spinner" uk-spinner=""></div>
    </div>
</div>