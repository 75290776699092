import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { CommonService } from "../common/common.service";

@Injectable({
  providedIn: 'root'
})
export class IciciService {

  constructor(private http: HttpClient, private commonService: CommonService) { }

  getICICIChecksum(): Observable<any> {
    return this.http.get('icicis/getChecksum/');
  }

  updateBroker(reqBody): Observable<any> {
    let userId = this.commonService.getUserId();
    return this.http.put(`users/${userId}/updatebrokerinfo`, reqBody);
  }


  getTradesDetails(reqBody): Observable<any> {
    reqBody.userId = this.commonService.getUserId();
    return this.http.post(`users/basketOrderWithEditGoalId`, reqBody);
  }

  getMarginValue(iciciUserId): Observable<any> {
    return this.http.get(`icicis/getISECMargin/${iciciUserId}`);
  }

  executeTrades(orderInfo): Observable<any> {
    return this.http.post(`icicis/executeTrades`, orderInfo);
  }

  getOrderStatus(): Observable<any> {
    return this.http.get(`icicis/getOrderUpdate`);
  }

  getPortfolio(): Observable<any> {
    return this.http.get(`icicis/getPortfolio`);
  }

  allocateFunds() {
    return this.http.get(`icicis/allocateFunds`);
  }
}
